import Avatar from '../Avatar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Logo } from '../../../assets/logo'
import { Leaf } from '@phosphor-icons/react'
import { CowIcon } from '../../../assets/cow'

export function handleEcosystem(location) {
  if (location.pathname !== '/') return '/'
  return '#ecosystem'
}

export function handleCommunity(location) {
  if (location.pathname !== '/') return '/'
  return '#community'
}
const color = 'text-laranja'

export default function NavbarMobile() {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <div
      className="text-branco flex flex-row items-center fixed w-full z-[60] h-20 border-b border-b-zinc-700"
      style={{
        // background: "linear-gradient(rgb(41,55,59),rgb(18,22,25)) ",
        background:
          'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(0,0,0, 0.7) 0%, rgba(0,0,0, 0.5447917) 77.08%, rgba(70, 144, 213, 0) 100%)',
        backdropFilter: 'blur(20px)',
      }}
    >
      <section className="flex flex-row items-center font-light lg:font-normal w-full text-xs lg:text-sm  justify-between px-5 lg:px-10 xl:px-20">
        <i className="flex items-center space-x-4">
          <Logo
            width={100}
            height={100}
            onClick={undefined}
            className={undefined}
            size={undefined}
          />
          <Leaf
            className={` hover:text-azul cursor-pointer ${location.pathname.startsWith('/') &&
                location.pathname !== '/pecuarist'
                ? 'text-azul'
                : 'text-white'
              }`}
            onClick={() => navigate(handleEcosystem(location))}
            size={20}
          />
          <CowIcon
            onClick={() => navigate('/pecuarist')}
            className={` hover:text-laranjaClaro cursor-pointer w-5 h-5 ${location.pathname === '/pecuarist'
                ? 'text-laranjaClaro'
                : 'text-white'
              }`}
          />
        </i>
        <div className="flex flex-row  items-center gap-10">
          <div className="flex gap-5 items-center">
            <Link to="/" className={location.pathname === '/' ? color : ''}>
              Início
            </Link>
            <Link
              to="/farms"
              className={location.pathname === '/farms' ? color : ''}
            >
              Fazendas
            </Link>
            <Link
              to="/marketplace"
              className={location.pathname === '/marketplace' ? color : ''}
            >
              Marketplace
            </Link>
            <Link
              to="/provenance"
              className={location.pathname === '/provenance' ? color : ''}
            >
              Como funciona
            </Link>
            <Link
              to="/whitepaper"
              className={location.pathname === '/whitepaper' ? color : ''}
            >
              Whitepaper
            </Link>
          </div>
          <div className="flex items-center gap-4">
            <Avatar />
          </div>
        </div>
      </section>
    </div>
  )
}
