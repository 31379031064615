import Footer from '../../Components/Footer/Index'
import { CircleLight } from '../../Components/Screen/circleLight'

export default function WhitepaperPage() {
  return (
    <div className="min-h-[calc(100vh_-_80px)] flex flex-col justify-between bg-preto relative">
      <CircleLight />
      <CircleLight left position="top-[32%]" />
      <section className="md:h-[50vh] w-full flex flex-col justify-center items-center  flex-grow ">
        <span className="lg:text-2xl text-branco text-center">
          Whitepaper, Tokenomics e Política de Participação em breve...
        </span>
      </section>
      <Footer />
    </div>
  )
}
