import Footer from '../../Components/Footer/Index'
import { CircleLight } from '../../Components/Screen/circleLight'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Header } from './components/Header'
import { Steps } from './components/Steps'

export default function ProvenancePage() {
  window.scrollTo(0, 0)

  const mediaQuery = useMediaQuery('(min-width: 1024px)')
  return (
    <div className="min-h-[calc(100vh_-_80px)] flex flex-col justify-between bg-preto relative">
      <CircleLight />
      <CircleLight left position="top-[32%]" />
      <CircleLight position="top-[52%]" />
      <CircleLight left position="top-[72%]" />

      <div className="lg:px-20 2xl:px-60 flex-grow z-20">
        <div className="flex flex-col items-center justify-center p-6 text-xs lg:items-start">
          <Header />

          <Steps mediaQuery={mediaQuery} />
        </div>
      </div>

      <Footer />
    </div>
  )
}
