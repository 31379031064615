import { Text } from '../../../Components/Screen/Text/Body'
import { Heading } from '../../../Components/Screen/Text/Heading'

export const Solution = () => {
  return (
    <div className="w-full flex flex-col md:text-center gap-5 bg-black/20 p-10 rounded-2xl">
      <Heading>
        Mais sobre nossa <span className="text-verdeClaro"> solução</span>
      </Heading>
      <Text>
        Criamos uma plataforma para criação, compra e venda de NFTs que
        representam propriedade de toneladas de terras não desmatadas.
      </Text>

      {/* TODO: card nft */}
    </div>
  )
}
