import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep1 } from '../assets/SvgStep1'

export const Step1 = ({ mediaQuery }) => {
  return (
    <article className="lg:flex lg:justify-between lg:items-center">
      <div className="lg:flex lg:flex-col">
        <Text className="flex flex-row gap-4 my-4 ">
          <SvgStep1 />
          <Text className="flex flex-col text-white">
            Etapa 1<Heading className="text-azul">Documentos</Heading>
          </Text>
        </Text>
        <ul className="px-6 font-medium list-disc lg:text-lg">
          <li>
            <Text>Comprovamos a propriedade da fazenda e seus direitos</Text>
          </li>
          <li>
            <Text>Registramos na nossa base de dados toda a documentação</Text>
          </li>
          <li>
            <Text>Oferecemos aval necessário para finalidades como crédito, rastreabilidade, garantias, etc.</Text>
          </li>
          <li>
            <Text>Geramos a documentação kml para sua fazenda para atualizar os seus registros</Text>
          </li>
        </ul>
      </div>
      {mediaQuery && (
        <img
          src="https://images.unsplash.com/photo-1591647620471-cffbb4ec2242?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          alt="art"
          className="w-1/3 h-[20rem]  rounded-lg"
          loading="eager"
        />
      )}
    </article>
  )
}
