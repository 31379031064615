import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep3 } from '../assets/SvgStep3'

export const Step3 = ({ mediaQuery }) => {
  return (
    <article className="flex flex-col gap-4 my-10 lg:flex-row lg:justify-between lg:items-center">
      <div className="flex flex-col gap-3">
        <Text className="flex flex-row items-center gap-4">
          <SvgStep3 />
          <Text className="flex flex-col text-white">
            Etapa 3 <Heading className="text-azul">Certificação de origem</Heading>
          </Text>
        </Text>
        <ul className="px-6 font-medium list-disc lg:text-lg">
          <li>
            <Text>
              Oferecemos traçabilidade do produto, da fazenda até o produtor
            </Text>
          </li>
          <li>
            <Text>Promovemos em ações positivas para a preservação e recuperação do meio ambiente, a sustentabilidade, o trabalho e bem-estar social, alavancando as melhores práticas de ESG aplicáveis</Text>
          </li>
          <li>
            <Text>
              Aumentamos o valor do produto, a penetração no mercado e o desenvolvimento de novos produtos
            </Text>
          </li>
          <li>
            <Text>
              Aumentamos a classificação de crédito por meio do monitoramento de cadeias de produtos e adoção de melhores práticas sustentáveis para acesso ás linhas de crédito e oferta
            </Text>
          </li>
          <li>
            <Text>
              Fornecemos às partes interessadas e autorizadas, dados consolidados e confiáveis
            </Text>
          </li>
          <li>
            <Text>
              Geramos tokens de produção com certificado de origem para venda antecipada pre-colheita
            </Text>
          </li>

        </ul>
      </div>
      {mediaQuery && (
        <img
          src="https://images.unsplash.com/photo-1627920769541-daa658ed6b59?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1633&q=80"
          alt="art"
          className="w-1/3 h-[20rem]  rounded-lg"
          loading="eager"
        />
      )}
    </article>
  )
}
