import React from 'react'
import { Text } from '../../../../Components/Screen/Text/Body'

export const ToastContact = ({ sendEmail, setIsToastOpen }) => {
  async function handleToast() {
    await sendEmail()
    setIsToastOpen(true)
    setTimeout(() => {
      setIsToastOpen(false)
    }, 3000)
  }

  return (
    <div className="w-full text-center items-center justify-center self-center py-10 px-4 text-branco font-light">
      <Text>
        Você está interessado nesta fazenda? Entre em contato conosco{' '}
        <Text
          className="font-bold text-laranja cursor-pointer"
          onClick={handleToast}
        >
          aqui
        </Text>
      </Text>
    </div>
  )
}
