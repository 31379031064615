import 'swiper/css'
import 'swiper/css/grid'
import { BackgroundImage } from '../../components/Desktop/BackgroundImage'
import { UserInfo } from '../../components/Desktop/UserInfo'
import Footer from '../../../../Components/Footer/Index'
import { FormEditProfileDesktop } from './Form'

export const DesktopPage = (props) => {
  if (!props.userData)
    return (
      <div className="bg-preto self-center flex">
        <div>Carregando...</div>
      </div>
    )

  return (
    <div className="flex flex-col justify-between relative">
      <div className="flex-grow min-h-[1000px]">
        <BackgroundImage {...props} />

        <UserInfo {...props} />

        <div className="absolute left-[26rem] top-[17rem] w-[35rem] lg:w-[w-45rem] 2xl:w-[65rem] xl:w-[50rem] flex flex-col gap-6">
          <FormEditProfileDesktop {...props} />
        </div>
      </div>
      <Footer />
    </div>
  )
}
