import React from 'react'
import { Text } from '../../../../Components/Screen/Text/Body'
import FarmMap from './MapContainer'

export const ArtInfos = (props) => {
  const { art } = props
  console.log(art)
  return (
    <div className="flex flex-col gap-4 pt-5 px-2 lg:px-0">
      <img
        src={art?.withoutqr}
        alt="art"
        className="w-full max-h-[500px] object-contain object-center rounded-lg"
      />
      <div className="flex gap-3 items-center justify-center w-full lg:text-2xl flex-col">
        <FarmMap nftId={art?.nftId} />
        <div className="flex flex-col gap-3">
          <Text className="text-laranja">
            Nome: <Text>{art?.name}</Text>
          </Text>
          <Text className="text-laranja">
            Registro: <Text>{art?.matricula || "Não informado"}</Text>
          </Text>
          <Text className="text-laranja">
            CAR: <Text>{art?.car || "Não informado"}</Text>
          </Text>
          <Text className="text-laranja">
            PRA: <Text>{art?.pra || "Não informado"}</Text>
          </Text>
        </div>
      </div>
    </div>
  )
}
