import { Subtext } from '../../../Components/Screen/Text/Body'

export const Partner = ({ name, description, image }) => {
  return (
    <div className="flex flex-col gap-3 items-center mt-3">
      <img src={image} alt={name} className="bg-fill object-center w-64" />
      <Subtext>{description}</Subtext>
    </div>
  )
}
