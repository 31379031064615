import { useNavigate } from 'react-router-dom'
import { Button } from '../../Components/Screen/Button/button'
import { CircleLight } from '../../Components/Screen/circleLight'
import { Subheading } from '../../Components/Screen/Text/Heading'

export const NotLogged = () => {
  const navigate = useNavigate()

  function handleLogin() {
    navigate('/login')
  }

  return (
    <>
      <CircleLight />
      <CircleLight position={'left-[20%] bottom-0'} />
      <section className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col items-center justify-center gap-10 z-20">
        <Subheading className={'text-error'}>
          Você não está logado, por favor faça login ou registre-se para
          continuar
        </Subheading>
        <Button className="w-48" onClick={handleLogin}>
          Login
        </Button>
      </section>
    </>
  )
}
