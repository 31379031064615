import axios from 'axios'

export const mintNFT = async (to, uri, tons) => {
  const address = process.env.REACT_APP_nft_contract
  try {
    const tx = await axios.post(
      `${process.env.REACT_APP_contract_api}/transferERC721`,
      {
        address,
        to,
        uri,
        tons,
      },
    )
    return tx
  } catch (error) {
    console.error(error)
  }
}
