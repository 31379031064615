import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Footer from '../../Components/Footer/Index'
import { TrendingArts } from './TrendingArts'
import { NewCollection } from './NewCollection'
import { NotLogged } from '../Error/NotLogged'
import { Header } from './components/Header'
import { SearchInput } from './components/SearchInput'
import { Provenance } from './components/Provenance'
import { FarmValidator } from './components/FarmValidator'
import { auth, db } from '../../firebase'
import { CircleLight } from '../../Components/Screen/circleLight'
import { child, get, ref } from 'firebase/database'

export default function ArtworksPage() {
  const isLogged = auth?.currentUser?.uid
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const [data, setData] = useState([])

  async function getFarms() {
    const dbRef = ref(db)
    setLoading(true)
    return get(child(dbRef, `users`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val()
          setData(users)
        } else {
          console.log('No data available')
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getFarms()
  }, [])

  const dataArray = Object.values(data ?? [])
    .map((art) => {
      return art?.art
    })
    .flat()
    .filter((art) => art)

  const arts = dataArray?.reduce((acc, curr) => {
    Object.values(curr).forEach((value) => {
      acc.push(value)
    })
    return acc.filter((art) => !art?.hide)
  }, [])

  const handleInfos = (art) => {
    navigate(`/farms/${art?.name}`, {
      state: { art, arts },
    })
  }

  let filtered
  if (search) {
    filtered = arts?.filter((art) => {
      return (
        art?.name.toLowerCase().includes(search.toLowerCase()) ||
        art?.matricula.toLowerCase().includes(search.toLowerCase()) ||
        art?.car.toLowerCase().includes(search.toLowerCase()) ||
        art?.pra.toLowerCase().includes(search.toLowerCase())
      )
    })
  } else {
    filtered = arts
  }

  return (
    <>
      {isLogged ? (
        <div className="bg-preto min-h-[calc(100vh_-_80px)] flex justify-between flex-col relative">
          <CircleLight position={' top-0 left-0'} />
          <CircleLight position={' top-0 right-0'} />
          <CircleLight position={' bottom-0 left-0'} />
          <CircleLight position={' bottom-0 right-0'} />

          <div className="z-20 flex flex-col items-center justify-start lg:px-20 flex-grow">
            <Header />

            <SearchInput {...{ search, setSearch }} />

            <TrendingArts
              data={filtered}
              handleInfos={handleInfos}
              loading={loading}
            />

            <Provenance />

            <NewCollection arts={arts} handleInfos={handleInfos} />

            <FarmValidator />
          </div>
          <Footer />
        </div>
      ) : (
        <NotLogged />
      )}
    </>
  )
}
