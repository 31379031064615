import { File, FilePlus, X } from '@phosphor-icons/react'
import { Subtext } from '../../../Components/Screen/Text/Body'
import { validateSize } from '../../../utils/validateSizeFile'

export const Inputs = ({
  imageFront,
  setImageFront,
  errSizeFront,
  setErrSizeFront,
  imageBack,
  setImageBack,
  errSizeBack,
  setErrSizeBack,
}) => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center p-5 w-full gap-10">
      <div className="uploads uploadsDocuments max-h-[17rem]">
        <label
          htmlFor="file"
          className="text-verdeClaro text-3xl hover:cursor-pointer"
        >
          {imageFront ? <File size={40} /> : <FilePlus size={40} />}
        </label>
        <input
          type="file"
          id="file"
          accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
          className="hidden"
          onChange={(e) =>
            validateSize(e.target.files[0], setErrSizeFront, setImageFront)
          }
        />
        {errSizeFront && (
          <Subtext className="text-red-500 text-sm">{errSizeFront}</Subtext>
        )}
        <div className="mt-10 w-full">
          <div className="flex flex-col text-center select-none">
            <Subtext className="w-full mb-2 rounded-2xl p-2 bg-verdeClaro text-preto">
              Frente do Documento
            </Subtext>
            <small>JPG, PNG ou PDF</small>
          </div>
        </div>
        {imageFront && (
          <>
            <img
              src={URL.createObjectURL(imageFront)}
              alt="upload"
              className="w-20 h-20 object-cover"
            />

            <X
              size={24}
              className="text-verdeClaro absolute right-3 top-2 hover:cursor-pointer"
              onClick={() => setImageFront(null)}
            />
          </>
        )}
      </div>
      <div className="uploads uploadsDocuments max-h-[17rem]">
        <label
          htmlFor="fileBack"
          className="text-verdeClaro text-3xl p-0 relative cursor-pointer"
        >
          {imageBack ? <File size={40} /> : <FilePlus size={40} />}
        </label>
        <input
          type="file"
          id="fileBack"
          accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
          className="hidden"
          onChange={(e) =>
            validateSize(e.target.files[0], setErrSizeBack, setImageBack)
          }
        />
        {errSizeBack && (
          <Subtext className="text-red-500">{errSizeBack}</Subtext>
        )}
        <div className="mt-10 w-full">
          <div className="flex flex-col text-center  select-none">
            <Subtext className="w-full mb-2 rounded-2xl p-2 bg-verdeClaro text-preto">
              Verso do Documento
            </Subtext>
            <small>JPG, PNG ou PDF</small>
          </div>
        </div>
        {imageBack && (
          <>
            <img
              src={URL.createObjectURL(imageBack)}
              alt="upload"
              className="w-20 h-20 object-cover"
            />
            <X
              size={24}
              className="text-verdeClaro absolute right-3 top-2 hover:cursor-pointer"
              onClick={() => setImageBack(null)}
            />
          </>
        )}
      </div>
    </div>
  )
}
