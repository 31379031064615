import styled from '@emotion/styled'

const Row = styled.div`
  margin: 30px auto;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.06);
  position: relative;
`

export default Row
