import FormField from './FormField'

const BillingDetailsFields = () => {
  return (
    <>
      <FormField name="name" label="Name" type="text" required />
      <FormField name="email" label="Email" type="email" required />
      <FormField name="address" label="Address" type="text" required />
      <FormField name="city" label="City" type="text" required />
      <FormField name="state" label="State" type="text" required />
      <FormField name="zip" label="ZIP" type="text" required />
    </>
  )
}

export default BillingDetailsFields
