import { Step1 } from './Steps/Step1'
import { Step2 } from './Steps/Step2'
import { Step3 } from './Steps/Step3'
import { Step4 } from './Steps/Step4'

export const Steps = ({ mediaQuery }) => {
  return (
    <section className="flex flex-col w-full my-10">
      <Step1 mediaQuery={mediaQuery} />

      <Step2 mediaQuery={mediaQuery} />

      <Step3 mediaQuery={mediaQuery} />

      <Step4 mediaQuery={mediaQuery} />
    </section>
  )
}
