export const InfoSquare = ({ children, link, ...props }) => {
  return (
    <div
      {...props}
      style={{
        background: 'linear-gradient(135deg, #1C684F 0%, #282828 100%)',
        boxShadow:
          '-2.88016px 2.88016px 5.76032px rgba(78, 78, 78, 0.2), 2.88016px -2.88016px 5.76032px rgba(78, 78, 78, 0.2), 2.88016px 2.88016px 7.29641px rgba(0, 0, 0, 0.66), inset 0.192011px 0.192011px 0.384021px rgba(255, 255, 255, 0.3), inset -0.192011px -0.192011px 0.384021px rgba(78, 78, 78, 0.5)',
      }}
      className={`relative w-20 h-20 md:w-32 md:h-32 flex justify-center align-middle items-center rounded-2xl transition duration-300 ease-in-out ${
        link
          ? 'cursor-pointer hover:scale-105 hover:shadow-lg'
          : 'cursor-not-allowed'
      }`}
      onClick={
        link
          ? () => window.open(link, '_blank')
          : () => {
              ''
            }
      }
    >
      {children}
    </div>
  )
}
