import { ArrowRight } from '@phosphor-icons/react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'

export function Step0ArtRegister({ userData, setStep }) {
  return (
    <div>
      <section className="flex flex-col items-center justify-between pt-10 w-full h-full">
        <div className="w-full p-5 text-center flex flex-col">
          <Heading className="text-verdeClaro mb-3">
            Caro(a) <span className="font-bold">{userData?.firstName}</span>
          </Heading>
          <Text className="text-branco font-light">
            Vamos começar a registrar sua fazenda. <br />
            São apenas 5 simples passos e você pode obter uma certificação NFT!
          </Text>
        </div>

        <div className="w-5/6">
          <div className="steps">
            <div className="item blue">
              <h5>1º</h5>
              <span>
                Registro
                <br />
                Aprovado
              </span>
            </div>
            <div className="item bege">
              <h5>2º</h5>
              <span>
                Documentos com
                <br />
                QR Code
              </span>
            </div>
            <div className="item blue">
              <h5>3º</h5>
              <span>
                Enviar
                <br /> Documentos
              </span>
            </div>
            <div className="item bege">
              <h5>4º</h5>
              <span>
                Detalhes do
                <br />
                Cultivo e Avaliação
              </span>
            </div>
            <div className="item orange">
              <h5>5º</h5>
              <span>
                A.I.
                <br /> e Certificação
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center pt-10 text-right pr-6 md:pr-32 justify-end">
          <ArrowRight
            size={48}
            weight="bold"
            className="text-verdeClaro hover:cursor-pointer"
            onClick={() => setStep(1)}
          />
        </div>
      </section>
    </div>
  )
}
