import { useEffect, useRef, useState } from 'react'
import { Logo } from '../../../../assets/logo'
import { Check, X } from '@phosphor-icons/react'
import useOnClickOutside from '../../../../utils/clickOutside'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'

export function ModalDocuments({ show, setShow, exhibitions, certificates }) {
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const allDocuments = exhibitions.concat(certificates)
    setDocuments(allDocuments)
  }, [certificates, exhibitions])

  const ref = useRef()
  useOnClickOutside(ref, () => {
    setShow(false)
  })

  return (
    <>
      <div
        className="flex flex-col items-center bg-preto p-4 rounded-md border-cinzaescuro border min-h-[50vh] relative"
        ref={ref}
      >
        <span
          className="text-laranja  absolute right-4 top-4 cursor-pointer"
          onClick={() => setShow(!show)}
        >
          <X size={32} />
        </span>
        <div className="flex justify-center mb-5">
          <Logo
            width="120"
            height="120"
            onClick={() => {
              ''
            }}
          />
        </div>
        <Subheading className="mb-4 text-laranja text-center">
          Avaliação de Cultivo
        </Subheading>
        {documents?.length > 0 ? (
          documents.map((document) => (
            <div
              key={document?.url}
              className="flex text-center flex-col md:flex-row items-center gap-1 md:gap-6 mb-4 w-full lg:text-xl select-none"
            >
              <div className="flex flex-col flex-1 font-semibold text-sm md:text-lg ">
                <Text>{document?.name}</Text>
              </div>
              <div className="text-3xl justify-center flex-1 hidden md:flex text-azul">
                <Check size={24} />
              </div>
              <div className="flex flex-col flex-1  text-2xs md:text-base text-azul ">
                <Text
                  className=" hover:cursor-pointer hover:underline"
                  onClick={() => {
                    window.open(document?.url, '_blank')
                  }}
                >
                  Ver documento
                </Text>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center">
            <Text>Sem documentos</Text>
          </div>
        )}
      </div>
    </>
  )
}
