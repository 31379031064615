import { Link } from 'react-router-dom'
import { auth } from '../../../firebase'
import { User } from '@phosphor-icons/react'
import { useGlobalContext } from '../../../Context/GlobalContext'

export default function Avatar() {
  const { userData: user } = useGlobalContext()
  const uid = auth?.currentUser?.uid

  const profilePicture = user ? user.profilePicture : ''

  const notification = ''

  const picture = profilePicture ? (
    <img
      src={profilePicture}
      alt="profile"
      className="w-10 h-10 rounded-full object-cover object-center "
      loading="eager"
    />
  ) : (
    <User size={24} />
  )

  return (
    <>
      {uid ? (
        <>
          <div className="text-center">
            {notification && (
              <span className="absolute z-10 w-6 h-6 p-1 ml-6 text-xs font-bold hover:cursor-default border border-laranja bg-black text-laranja flex items-center justify-center rounded-full">
                *
              </span>
            )}
          </div>
          <Link to="/profile" className="flex items-center justify-center">
            {picture}
          </Link>
        </>
      ) : (
        <Link to="/login">
          <User size={24} />
        </Link>
      )}
    </>
  )
}
