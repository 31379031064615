import { PaperPlaneTilt } from '@phosphor-icons/react'
import { Subtext } from '../../../Screen/Text/Body'

export const Infos = () => {
  async function handleSubscribe(email) {
    console.log(email)
    return alert('Você foi inscrito com sucesso! 🎉')
  }

  return (
    <div className="flex flex-row items-center justify-center gap-2">
      <form className="flex flex-row"
        onSubmit={(e) => {
          e.preventDefault()
          handleSubscribe(e.target[0].value)
        }}
      >
        <Subtext className={'self-center w-full'}>Fique atualizado!</Subtext>
        <input
          type="text"
          placeholder="✉ Email"
          className="bg-transparent w-full max-w-xs border-b py-2 focus:outline-none"
        />
        <button
          type="submit"
          className='ml-2'
        >
          <PaperPlaneTilt size={24} />
        </button>
      </form>
    </div>
  )
}
