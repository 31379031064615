import React from 'react'
import { Subtext } from '../../../../Components/Screen/Text/Body'

export const ToastEmail = ({ isEmailSended, isToastOpen }) => {
  if (!isEmailSended && isToastOpen) {
    return (
      <div className="toast toast-end toast-top z-50 pt-32">
        <div className="alert alert-success">
          <Subtext className={'text-black'}>
            Obrigado pelo seu interesse nesta fazenda. Entraremos em contato em
            breve.
          </Subtext>
        </div>
      </div>
    )
  }
}
