
export const CountStats = () => {
  return (
    <section className="flex items-center justify-around px-2 md:px-40  bg-black/20 backdrop-blur py-5 space-x-1">
      <Text className={'flex flex-col items-center justify-center'}>
        Fazendas
        <Subtext className="font-semibold text-azul">+100</Subtext>
      </Text>
      <Text className={'flex flex-col items-center justify-center'}>
        Em Análise
        <Subtext className="font-semibold text-azul">+70</Subtext>
      </Text>
      <Text className={'flex flex-col items-center justify-center'}>
        Certificados ESG
        <Subtext className="font-semibold text-azul">+50</Subtext>
      </Text>
      <Text className={'flex flex-col items-center justify-center'}>
        Toneladas em compliance
        <Subtext className="font-semibold text-azul">+20.000</Subtext>
      </Text>
    </section>
  )
}

const Text = ({ children, className, as, ...rest }) => {
  const Component = as || 'span'
  return (
    <Component
      className={`text-sm sm:text-lg font-light ${className || 'text-gray-50'
        }`}
      {...rest}
    >
      {children}
    </Component>
  )
}

const Subtext = ({ children, className, as, ...rest }) => {
  const Component = as || 'span'
  return (
    <Component
      className={`text-sm sm:text-base font-light ${className || 'text-gray-50'
        }`}
      {...rest}
    >
      {children}
    </Component>
  )
}