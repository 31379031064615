import { useEffect, useState } from 'react'
import MintNFT from './mintNFT'
import { File, FilePlus, X } from '@phosphor-icons/react'
import StorageClient from '../../../../utils/storageFile'
import { ModalNFT } from './modalNFT'
import { Button } from '../../../../Components/Screen/Button/button'
import {
  Input as InputMy,
  InputDiv,
} from '../../../../Components/Screen/Input/Text'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { createMetadata } from '../../../../utils/web3/createMetadata'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { auth, db } from '../../../../firebase'
import { ref, update } from 'firebase/database'
import { useGlobalContext } from '../../../../Context/GlobalContext'

export default function ArtRegisterCreateNFTPage({
  setStep,
  step2,
  setNft,
  userData,
  step4,
}) {
  const productionCapacity = step4?.deflorestation?.yieldEstimation
  const farmName = step2?.name
  const ownerName = userData.firstName + ' ' + userData.lastName
  const certificate = step4?.deflorestation?.certificationType
  const matricula = step2?.matricula
  const car = step2?.car
  const pra = step2?.pra
  const legalReserve = step4?.deflorestation?.legalReserve
  const permanentPreservedArea = String(
    step4?.deflorestation?.permanentPreservedArea,
  )
  const files = [...step4?.deflorestation?.file, ...step4?.cropDetails?.file]
  const [image, setImage] = useState(step2?.withoutQR || '')
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [nfts, setNfts] = useState([])
  const [ipfs, setIpfs] = useState('')
  const [mintTickets, setMintTickets] = useState(0)
  const { updateUser } = useGlobalContext()
  const uid = auth?.currentUser?.uid

  const user = userData
  const data = user?.ipfs

  useEffect(() => {
    if (user) {
      setMintTickets(user?.mintTicket)
    }
  }, [user])

  useEffect(() => {
    setNfts(data?.map((nft) => nft) || [])
  }, [data])

  async function setTons() {
    try {
      const newTons = Number(productionCapacity) + Number(user?.tons || 0)
      update(ref(db, 'users/' + uid), {
        tons: newTons,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitNFT = async (e) => {
    e.preventDefault()
    setLoading(true)
    const nftImage = await new StorageClient().storeFiles(image)
    const todayPlus6Months = new Date(
      new Date().setMonth(new Date().getMonth() + 6),
    ).toLocaleDateString('en-US')

    const metadata = {
      name: farmName,
      image: nftImage,
      parcelId: step4?.cropDetails?.parcelId,
      files,
      attributes: [
        { trait_type: 'Farm registration', value: matricula },
        { trait_type: 'CAR', value: car || 'Due to submit - til expiration' },
        { trait_type: 'PRA', value: pra || 'Due to submit - til expiration' },
        {
          trait_type: 'Legal Reserve',
          value: legalReserve > 30 ? 'Compliant' : 'Not Compliant',
        },
        {
          trait_type: 'Production in Tons',
          value: productionCapacity.toFixed(2),
        },
        {
          trait_type: 'Permanent Preserved Area',
          value: permanentPreservedArea || 'N/A',
        },
        {
          trait_type: 'Certificate Type',
          value: certificate,
        },
        {
          trait_type: 'Expiration Date of Certificate',
          value: todayPlus6Months,
        },
        {
          trait_type: 'Central Geo Location of Parcel',
          value: `${step4?.cropDetails?.parcelGeolocation.latitude}, ${step4?.cropDetails?.parcelGeolocation.longitude}`,
        },
        {
          trait_type: 'Crop Type',
          value: step4?.cropDetails?.cropType,
        },
        {
          trait_type: 'Harvest Year',
          value: step4?.cropDetails?.harvestYear,
        },
      ],
    }

    const ipfs = await createMetadata('metadata.json', metadata)

    const nft = ipfs
    setIpfs(nft)
    const newNfts = [...nfts, nft]
    setNft(newNfts)
    await setTons()
    await updateUser()
    setLoading(false)
    setDone(true)
  }

  return (
    <div className="bg-preto">
      <ModalNFT
        open={open}
        setOpen={setOpen}
        setStep={setStep}
        mintTickets={mintTickets}
        userData={userData}
      />

      <section className="flex flex-col items-center justify-center px-2 md:px-20 bg-preto">
        <div className="flex items-center w-full gap-3  ">
          <Heading as={'h2'} className="text-verdeClaro pt-4">
            Criação do NFT
          </Heading>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <form
            onSubmit={handleSubmitNFT}
            className="relative flex flex-col w-full gap-4  mt-8"
          >
            <Input name="Nome do Proprietário" value={ownerName} />

            <Input name="Matricula" value={matricula} />

            <Input
              name="Estimativa de Produção"
              value={productionCapacity + ' Tons'}
            />

            {car && (
              <>
                <Input name="CAR" value={car} />
                <Input name="Reserva Legal" value={legalReserve + ' %'} />
                <Input
                  name="Área Permanente Preservada"
                  value={permanentPreservedArea + ' ha'}
                />
              </>
            )}

            <div className="w-full flex items-center justify-center flex-col">
              {!image && (
                <>
                  <Text
                    as={'label'}
                    htmlFor="nftImage"
                    className={'cursor-pointer text-verdeClaro'}
                  >
                    {image ? <File size={40} /> : <FilePlus size={40} />}
                  </Text>
                  <input
                    type="file"
                    id="nftImage"
                    accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
                    className="hidden"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </>
              )}

              {image && (
                <div className="w-32 flex flex-col">
                  <div className="self-center flex items-center justify-center pt-6 max-w-screen-md">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="upload"
                      className="object-contain w-32 h-32"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="w-full px-5 text-right pb-4">
              {!done && loading && (
                <Button type="button">Criando seu NFT, aguarde...</Button>
              )}
              {!done && !loading && mintTickets > 0 && (
                <Button
                  type="button"
                  disabled={!image || loading}
                  onClick={(e) => {
                    handleSubmitNFT(e)
                  }}
                >
                  Upload
                </Button>
              )}

              {done && mintTickets > 0 && (
                <MintNFT
                  uri={ipfs}
                  setStep={setStep}
                  userData={userData}
                  tons={productionCapacity}
                />
              )}
            </div>
          </form>
        )}
      </section>
    </div>
  )
}

const Input = ({
  name,
  value,
  // setValue,
  type = 'text',
  disabled = true,
  placeholder,
}) => {
  return (
    <InputDiv flexCol>
      <Text as="label" htmlFor={name}>
        {name}
      </Text>
      <InputMy
        type={type}
        id={name}
        value={value}
        placeholder={placeholder}
        // onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      />
    </InputDiv>
  )
}
