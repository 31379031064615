import { Gear } from '@phosphor-icons/react'
import { ChangeBackgroundPic } from '../ChangeBackgroundPic'

export const BackgroundImage = (props) => {
  const { userData, isGearBackgroundOpen, handleGearBackground } = props
  return (
    <div className=" w-full h-64 z-10">
      {isGearBackgroundOpen && <ChangeBackgroundPic {...props} />}
      <img
        src={
          userData?.backgroundPicture ||
          'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
        }
        alt="Background"
        className="object-cover  w-full h-64"
        loading="eager"
      />

      <div
        className="absolute right-2 z-40 cursor-pointer top-6 lg:top-2 text-branco bg-black/30 backdrop-blur flex justify-center items-center p-2 rounded-2xl"
        onClick={handleGearBackground}
      >
        <Gear size={24} />
      </div>
    </div>
  )
}
