export const OptionRadio = ({ setOptions, options }) => {
  return (
    <div className="flex flex-row justify-between text-xs font-medium pt-6 px-2">
      <button
        className={`active:text-laranja focus:text-laranja lg:text-2xl ${
          options === 'NFTCollected' ? 'text-laranja' : ''
        }`}
        onClick={() => {
          setOptions('NFTCollected')
        }}
      >
        Coleção de NFTs
      </button>
      <button
        className={`active:text-laranja focus:text-laranja lg:text-2xl ${
          options === 'NFTCreated' ? 'text-laranja' : ''
        }`}
        onClick={() => {
          setOptions('NFTCreated')
        }}
      >
        NFT Criados
      </button>
      <button
        className={`active:text-laranja focus:text-laranja lg:text-2xl ${
          options === 'CollectionManagement' ? 'text-laranja' : ''
        }`}
        onClick={() => {
          setOptions('CollectionManagement')
        }}
      >
        Gerenciamento de coleção
      </button>
    </div>
  )
}
