import { useState } from 'react'
import CropDetails from './CropDetails'
import DeflorestationAssesment from './DeflorestationAssesment'
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'

export default function ArtRegisterStep4Page({
  setStep,
  step4,
  setStep4,
  setExhibitionDone,
  exhibitionDone,
  certificateDone,
  setCertificateDone,
  bookDone,
  setBookDone,
  step2,
}) {
  const [provenanceModal, setProvenanceModal] = useState(false)
  const [artExhibitionModal, setArtExhibitionModal] = useState(false)
  const [certificateModal, setCertificateModal] = useState(false)
  const [bookModal, setBookModal] = useState(false)

  const props = {
    setStep,
    step4,
    setStep4,
    setExhibitionDone,
    exhibitionDone,
    certificateDone,
    setCertificateDone,
    bookDone,
    setBookDone,
    provenanceModal,
    setProvenanceModal,
    artExhibitionModal,
    setArtExhibitionModal,
    certificateModal,
    setCertificateModal,
    bookModal,
    setBookModal,
    step2,
  }

  return (
    <section className="flex flex-col md:px-20">
      <div className="w-full flex items-center  mt-16 px-4 gap-3">
        <span
          className="text-laranja text-5xl hover:cursor-pointer"
          onClick={() => setStep(2)}
        >
          <ArrowLeft size={32} weight="bold" />
        </span>
        <Heading className={'text-verdeClaro'}>
          Informações sobre a propriedade
        </Heading>
      </div>

      <Text className={'px-5 text-gray-300'}>
        É uma etapa importante para que possamos entender melhor a sua
        propriedade.
      </Text>

      <div className="w-full px-5 mt-8 flex flex-col gap-4">
        <CropDetails props={props} />

        <DeflorestationAssesment props={props} />

        <div className="w-full flex flex-row-reverse items-end lg:mt-72 lg:pr-20">
          <button
            className="text-laranja text-5xl hover:cursor-pointer"
            onClick={() => setStep(5)}
          >
            <ArrowRight size={48} weight="bold" />
          </button>
        </div>
      </div>
    </section>
  )
}
