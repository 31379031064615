import 'primeicons/primeicons.css'
import 'primereact/resources/themes/arya-green/theme.css'
import 'primereact/resources/primereact.min.css'
import { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Check, WarningCircle, X } from '@phosphor-icons/react'
import StorageClient from '../../../../utils/storageFile'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { Subtext, Text } from '../../../../Components/Screen/Text/Body'
import { Input, InputDiv } from '../../../../Components/Screen/Input/Text'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { InputSwitch } from 'primereact/inputswitch'

export default function DeflorestationAssesment({ props }) {
  const {
    bookModal,
    setBookModal,
    step4,
    setStep4,
    bookDone,
    setBookDone,
    step2,
  } = props
  const [carNumber, setCarNumber] = useState(step2?.car || '')
  const [carIncompliance, setCarIncompliance] = useState(
    step4?.deflorestation?.carIncompliance || false,
  )
  const [legalReserve, setLegalReserve] = useState(
    step4?.deflorestation?.legalReserve || 0,
  )
  const [permanentPreservedArea, setPermanentPreservedArea] = useState(
    step4?.deflorestation?.permanentPreservedArea || 0,
  )
  const [pra, setPra] = useState(step2?.pra || '')
  const [certificationType, setCertificationType] = useState(
    step4?.deflorestation?.certificationType || 'N/A',
  )
  // const [auditBy, setAuditBy] = useState('Farmie')
  const auditBy = 'Farmie'
  const [yieldEstimation, setYieldEstimation] = useState(
    step4?.deflorestation?.yieldEstimation || '',
  )
  const [certificateFile, setCertificateFile] = useState(
    step4?.deflorestation?.files || null,
  )
  const [addLoading, setAddLoading] = useState(false)

  const handleModal = () => {
    setBookModal(!bookModal)
  }

  const haveCar = step2?.car
  const havePra = step2?.pra

  const handleSubmit = async (event) => {
    event.preventDefault()

    setAddLoading(true)

    const files = certificateFile
    if (files) {
      const promises = []
      for (const element of files) {
        promises.push({
          url: await new StorageClient().storeFiles(element),
          name: element.name,
        })
      }
      const urls = await Promise.all(promises)

      const deflorestation = {
        carNumber,
        carCompliance: carIncompliance,
        legalReserve,
        permanentPreservedArea,
        pra,
        certificationType,
        auditBy,
        yieldEstimation,
        files,
        file: urls || [],
      }

      setBookDone(true)
      setStep4((prevState) => ({ ...prevState, deflorestation }))
      setAddLoading(false)
      setBookModal(false)
    } else {
      const deflorestation = {
        carNumber,
        carCompliance: carIncompliance,
        legalReserve,
        permanentPreservedArea,
        pra,
        certificationType,
        auditBy,
        yieldEstimation,
        files: [],
        file: [],
      }

      setBookDone(true)
      setStep4((prevState) => ({ ...prevState, deflorestation }))
      setAddLoading(false)
      setBookModal(false)
    }
  }

  useEffect(() => {
    const yieldEstimationFromCrop = step4?.cropDetails?.yieldEstimation
    if (yieldEstimationFromCrop) {
      setYieldEstimation(yieldEstimationFromCrop)
    }
  }, [step4?.cropDetails?.yieldEstimation])

  return (
    <>
      <div className="flex items-center mb-5 w-full ">
        <Heading className="cursor-pointer text-branco" onClick={handleModal}>
          Degradação Florestal
        </Heading>

        <i
          onClick={handleModal}
          className={`ml-2 ${bookDone ? 'text-verdeClaro' : ' text-warning'
            } text-4xl hover:cursor-pointer`}
        >
          {bookDone ? <Check size={36} /> : <WarningCircle size={36} />}
        </i>
      </div>
      {bookModal && (
        <div className="modal modal-open backdrop-blur">
          <div className="relative modal-box bg-preto border border-gray-500">
            {!addLoading && (
              <X
                size={32}
                onClick={handleModal}
                className="absolute text-2xl text-laranja right-2 top-2 hover:cursor-pointer"
              />
            )}

            <Subheading
              as="h1"
              className="mb-4 text-2xl font-bold text-center text-laranja"
            >
              Degradação Florestal
            </Subheading>
            {addLoading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <InputSwitchForm
                  name="Conformidade com o CAR"
                  value={step4?.carIncompliance || carIncompliance}
                  setValue={setCarIncompliance}
                />

                <div className="w-full flex flex-col">
                  <Text
                    as={'label'}
                    htmlFor={'Yield Estimation'}
                    className="flex gap-2 text-branco mb-2"
                  >
                    Área produtiva
                  </Text>
                  <InputNumber
                    value={permanentPreservedArea}
                    onValueChange={(e) => setPermanentPreservedArea(e.value)}
                    min={1}
                    className="calendarInput"
                    suffix=" ha"
                  />
                </div>
                <div className="w-full flex flex-col">
                  <Text
                    as={'label'}
                    htmlFor={'Yield Estimation'}
                    className="flex gap-2 text-branco mb-2"
                  >
                    Reserva Legal
                  </Text>
                  <InputNumber
                    value={legalReserve}
                    onValueChange={(e) => setLegalReserve(e.value)}
                    suffix=" %"
                    min={1}
                    max={100}
                    minFractionDigits={2} maxFractionDigits={5}
                    className="calendarInput"
                  />
                </div>
                {haveCar ? (
                  <InputDeforestation
                    name="CAR"
                    value={carNumber}
                    setValue={setCarNumber}
                  />
                ) : null}
                {havePra ? (
                  <InputDeforestation
                    name="PRA"
                    value={pra}
                    setValue={setPra}
                  />
                ) : null}

                <InputDropdown
                  name="Tipo de Certificação"
                  value={certificationType}
                  setValue={setCertificationType}
                  list={certificatesTypesList}
                  placeholder="N/A"
                />

                {/* <InputDropdown
                  name="Auditoria por"
                  value={auditBy}
                  setValue={setAuditBy}
                  list={auditorList}
                  placeholder="N/A"
                /> */}
                <div className="w-full flex flex-col">
                  <Text
                    as={'label'}
                    htmlFor={'Yield Estimation'}
                    className="flex gap-2 text-branco mb-2"
                  >
                    Estimativa de Rendimento
                  </Text>
                  <InputNumber
                    value={yieldEstimation}
                    onValueChange={(e) => setYieldEstimation(e.value)}
                    suffix=" Tons"
                    min={1}
                    className="calendarInput"
                  />
                </div>
                <Subtext>
                  Avaliação da Degradação Florestal Verificada pelo Método de
                  Conformidade Farmie com base em análise de evidências{' '}
                </Subtext>
                <div className="flex my-4 flex-col">
                  {!certificateFile && (
                    <>
                      <input
                        type="file"
                        id="documentosCultivo"
                        multiple
                        accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
                        className="hidden"
                        onChange={(e) => setCertificateFile(e.target.files)}
                      />
                      <ButtonOutline
                        as="label"
                        htmlFor="documentosCultivo"
                        className="cursor-pointer mb-2 max-w-fit self-center"
                      >
                        Upload de Documentos
                      </ButtonOutline>
                    </>
                  )}
                  {certificateFile && certificateFile?.length > 0 && (
                    <div className="flex items-center justify-center gap-1">
                      <div className="text-xs text-center">
                        {certificateFile?.length || '0'} arquivo
                        {certificateFile?.length > 1 ? 's' : ''} selecionado
                        {certificateFile?.length > 1 ? 's' : ''}
                      </div>
                      <X
                        size={28}
                        onClick={() => setCertificateFile(null)}
                        className="text-error cursor-pointer"
                      />
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-center">
                  <ButtonOutline>
                    {addLoading ? 'Carregando...' : 'Salvar'}
                  </ButtonOutline>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const InputDeforestation = ({ name, value, setValue }) => {
  return (
    <div className="flex flex-col border-b-2 gap-2">
      <Text as="label" htmlFor={name} className="flex text-branco">
        {name}
      </Text>
      <Input
        id={name}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        readOnly
      />
    </div>
  )
}

const InputSwitchForm = ({ name, value, setValue }) => {
  return (
    <div className="flex gap-2 justify-between">
      <Text as="label" htmlFor={name} className="flex  text-branco">
        {name}
      </Text>
      <InputSwitch
        onChange={(e) => setValue(e.value)}
        id={name}
        checked={value}
      />
    </div>
  )
}

const InputDropdown = ({
  name,
  value,
  setValue,
  list,
  placeholder,
  editable,
}) => {
  return (
    <div>
      <InputDiv flexCol>
        <Text as="label" htmlFor={name} className="flex text-branco">
          {name}
        </Text>
        <Dropdown
          options={list}
          value={value}
          optionLabel="name"
          placeholder={placeholder}
          editable={editable || false}
          onChange={(e) => setValue(e.target.value)}
          defaultValue="N/A"
          className=" w-full calendarInput"
        />
      </InputDiv>
    </div>
  )
}

const certificatesTypesList = [
  { name: 'N/A', value: 'N/A' },
  { name: 'Condicional', value: 'Conditional' },
  { name: 'Madeira', value: 'Wood' },
  { name: 'Bronze', value: 'Bronze' },
  { name: 'Prata', value: 'Silver' },
  { name: 'Ouro', value: 'Gold' },
  { name: 'Platina', value: 'Platinum' },
]

// const auditorList = [
//   { name: 'N/A', value: 'N/A' },
//   { name: 'Bernardo', value: 'HkrWJ28HyhSuX4F0WOdcWUeTMyD2' },
//   { name: 'Douglas', value: 'xoivusV5laYNUENQrxW0urw6hSg2' },
// ]
