import SimpleFooter from '../../../../Components/SimpleFooter/Index'
import Collection from './Collection'
import NFTCollected from './NFTCollected'
import NFTCreated from './NFTCreated'
import { BgImage } from './BgImage'
import { OptionRadio } from './OptionRadio'
import { UserInfo } from './UserInfo'

export const MobilePage = (props) => {
  const { userData, options } = props

  if (!userData)
    return (
      <div className="bg-preto self-center flex">
        <div>Carregando...</div>
      </div>
    )

  return (
    <>
      <section className="flex flex-col items-center justify-center w-full mb-28">
        <BgImage {...props} />

        <UserInfo {...props} />
      </section>
      <OptionRadio {...props} />

      {options === 'NFTCollected' && <NFTCollected {...props} />}
      {options === 'NFTCreated' && <NFTCreated {...props} />}
      {options === 'CollectionManagement' && <Collection {...props} />}
      <SimpleFooter />
    </>
  )
}
