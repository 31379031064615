import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useEffect, useState } from 'react'
import App from './Pages/Home/Index'
import ArtworksPage from './Pages/farms/Index'
import FarmsViewPage from './Pages/farms/View/Index'
import ErrorPage from './Pages/Error/Error'
import { useGlobalContext } from './Context/GlobalContext'
import LoginPage from './Pages/Login/LoginPage'
import NavbarDesktop from './Components/Navbar/Desktop'
import NavbarMobile from './Components/Navbar/Mobile'
import ProfilePage from './Pages/Profile/Index'
import ProvenancePage from './Pages/Provenance/Provenance'
import RegisterDocumentsPage from './Pages/Register/Documents/RegisterDocuments'
import RegisterPage from './Pages/Register/Register'
import WhitepaperPage from './Pages/Whitepaper/Whitepaper'
import { NeedDocuments } from './Components/Toast/NeedDocuments'
import FarmRegisterPage from './Pages/Farm/Register/FarmRegister'
import { MarketplacePage } from './Pages/Marketplace/Marketplace'
import { Loading } from './Components/Screen/Loading/Loading'
import Coordenadas from './Components/dmsConverter/DMSComponent'
import { auth } from './firebase'
import EditProfilePage from './Pages/Profile/EditProfile/Index'
import HomePecuarist from './Pages/HomePecuarist/Index'

export default function RoutesApp() {
  const { mediaQuery, userData } = useGlobalContext()
  const [isNeedToRegister, setIsNeedToRegister] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (userData && auth?.currentUser?.uid) {
      setIsLoading(false)
      if (!userData?.DocumentImageBack || !userData?.DocumentImageFront)
        return setIsNeedToRegister(true)

      setIsNeedToRegister(false)
    } else {
      setIsLoading(false)
      setIsNeedToRegister(false)
    }

  }, [userData])

  if (isLoading) return <Loading className="h-screen" />

  return (
    <BrowserRouter>
      <div
        className={`w-full h-20  ${isNeedToRegister ? 'mb-[80px]' : ''} ${mediaQuery === 'mobile' && isNeedToRegister && 'mb-20'
          }`}
      >
        {mediaQuery === 'mobile' ? <NavbarMobile /> : <NavbarDesktop />}

        {isNeedToRegister && userData && (
          <NeedDocuments
            text={'Para continuar, você precisa cadastrar seus documentos.'}
          />
        )}
      </div>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register/documents" element={<RegisterDocumentsPage />} />
        <Route path="/farm/register" element={<FarmRegisterPage />} />
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/whitepaper" element={<WhitepaperPage />} />
        <Route path="/provenance" element={<ProvenancePage />} />
        <Route path="/farms" element={<ArtworksPage />} />
        <Route path="/farms/:artId" element={<FarmsViewPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="/profile/editProfile" element={<EditProfilePage />} />
        <Route path="/pecuarist" element={<HomePecuarist />} />
        <Route path="dms" element={<Coordenadas />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  )
}
