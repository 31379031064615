import { signInWithEmailAndPassword } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../../firebase'
import { useGlobalContext } from '../../Context/GlobalContext'

export function useLogin() {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { setCurrentUser } = useGlobalContext()

  async function login(email, password, event) {
    event.preventDefault()
    if (!email || !password) {
      setError({ message: 'O campo de e-mail e senha não podem estar vazios' })
      return
    }
    setLoading(true)
    try {
      const user = await signInWithEmailAndPassword(auth, email, password)
      setCurrentUser(user?.user)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [error])

  return { error, loading, login }
}
