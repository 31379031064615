import { X } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { ButtonOutline } from '../../../Components/Screen/Button/button'
import { Text } from '../../../Components/Screen/Text/Body'
import { Heading } from '../../../Components/Screen/Text/Heading'

export const ModalSkip = ({ handleCloseSkip }) => {
  const navigate = useNavigate()
  const handleCloseSend = () => {
    navigate('/')
  }
  return (
    <div
      className="modal modal-open backdrop-blur-md w-full h-full z-30"
      id="modal-confirm"
    >
      <div className="modal-box flex flex-col items-center relative bg-preto border border-gray-500 py-10">
        <X
          size={32}
          className="text-laranja absolute right-3 top-2 hover:cursor-pointer"
          onClick={handleCloseSkip}
        />
        <Heading className="text-laranja">Bem-vindo(a) a Farmie</Heading>
        <Text className="py-4 text-gray-50">
          Sem enviar documentos, a sua participação será apenas para
          visualização.
        </Text>
        <ButtonOutline onClick={handleCloseSend} className="w-52 ">
          Pular
        </ButtonOutline>
      </div>
    </div>
  )
}
