import { Text } from '../../../../Components/Screen/Text/Body'
import NFTCard from '../NFTCard'

export default function NFTCreated({ nftsCreated }) {
  return (
    <div className="px-4 py-6">
      <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 gap-5">
        {nftsCreated?.map((nft, index) => (
          <div key={index} className="flex flex-col relative">
            <NFTCard nft={nft} />
          </div>
        ))}
        {nftsCreated?.length === 0 && (
          <div className="flex flex-row items-center justify-center">
            <Text>Nenhum NFT encontrado</Text>
          </div>
        )}
      </div>
    </div>
  )
}
