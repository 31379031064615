import { ref, update } from 'firebase/database'
import { useState } from 'react'
import { Button } from '../../../../Components/Screen/Button/button'
import { auth, db } from '../../../../firebase'
import { mintNFT } from '../../../../utils/web3/mintNFT'
import { useGlobalContext } from '../../../../Context/GlobalContext'

export default function MintNFT({ uri, setStep, userData, tons }) {
  const [isFetching, setIsFetching] = useState(false)
  const { updateUser } = useGlobalContext()
  const to = userData?.wallet

  return (
    <div>
      <Button
        type="button"
        onClick={async () => {
          try {
            setIsFetching(true)
            await mintNFT(to, uri, tons.toString())
            await update(ref(db, 'users/' + auth?.currentUser?.uid), {
              mintTicket: parseInt(userData?.mintTicket) - 1,
            })
            setStep(7)
            await updateUser()
          } catch (err) {
            console.error(err)
          } finally {
            setIsFetching(false)
          }
        }}
        disabled={isFetching}
      >
        {isFetching ? 'Criando seu NFT, por favor aguarde...' : 'Criar NFT!'}
      </Button>
    </div>
  )
}
