import { db } from '../../../../firebase'
import { ref, update } from 'firebase/database'
import { InputMask } from 'primereact/inputmask'
import { toast } from 'react-toastify'
import { Input, InputDiv } from '../../../../Components/Screen/Input/Text'
import { Button } from '../../../../Components/Screen/Button/button'
import { formatPhone, formatZip } from '../../../../utils/format-data'
import { ArrowCircleLeft } from '@phosphor-icons/react'

export const FormEditProfileDesktop = (props) => {
  async function updateUser(e) {
    try {
      e.preventDefault()
      const form = e.target
      const firstName = form.firstName.value
      const lastName = form.lastName.value
      const email = form.email.value
      const cnpj = form.cnpj.value
      const phone = form.phone.value
      const cep = form.cep.value
      const address = form.address.value
      const city = form.city.value
      const state = form.state.value
      const country = form.country.value

      const userData = {
        firstName,
        lastName,
        email,
        cnpj,
        phone: formatPhone(phone),
        zipCode: formatZip(cep),
        address,
        city,
        state,
        country,
      }
      await update(ref(db, 'users/' + props.auth.currentUser.uid), {
        ...userData,
      })
      toast('Perfil atualizado com sucesso', {
        theme: 'dark',
        type: 'success',
      })
      props.navigate('/profile')
    } catch (error) {
      toast('Erro ao atualizar perfil', {
        theme: 'dark',
        type: 'error',
      })
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center px-10">
        <ArrowCircleLeft
          size={32}
          className="cursor-pointer hover:text-azul"
          onClick={() => props.navigate('/profile')}
        />
        <h1 className="text-2xl font-bold text-center">Editar Perfil</h1>
        <div className="w-8" />
      </div>
      <form
        className="flex flex-col gap-4 w-full p-5 items-center mt-5"
        onSubmit={updateUser}
      >
        <div className="flex space-x-20 w-full items-center justify-center">
          <InputDiv flexCol>
            <label htmlFor="firstName">Nome</label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Nome"
              defaultValue={props.userData?.firstName}
            />
          </InputDiv>

          <InputDiv flexCol>
            <label htmlFor="lastName">Sobrenome</label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Sobrenome"
              defaultValue={props.userData?.lastName}
            />
          </InputDiv>
        </div>
        <div className="flex space-x-20 w-full items-center justify-center">
          <InputDiv flexCol>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              defaultValue={props.userData?.email}
            />
          </InputDiv>

          <InputDiv flexCol>
            <label htmlFor="cnpj">CNPJ/CPF</label>
            <InputMask
              type="text"
              name="cnpj"
              id="cnpj"
              mask="99999999999?999"
              placeholder="CNPJ/CPF"
              value={props.userData?.cnpj}
              defaultValue={props.userData?.cnpj}
            />
          </InputDiv>
        </div>
        <div className="flex space-x-20 w-full items-center justify-center">
          <InputDiv flexCol>
            <label htmlFor="phone">Telefone</label>
            <InputMask
              type="tel"
              name="phone"
              id="phone"
              placeholder="Telefone"
              value={props.userData?.phone}
              defaultValue={props.userData?.phone}
              mask="99-99999-999?9"
            />
          </InputDiv>

          <InputDiv flexCol>
            <label htmlFor="cep">CEP</label>
            <InputMask
              name="cep"
              id="cep"
              placeholder="CEP"
              defaultValue={props.userData?.zipCode}
              value={props.userData?.zipCode}
              mask="99999-999"
            />
          </InputDiv>
        </div>
        <div className="flex space-x-20 w-full items-center justify-center">
          <InputDiv flexCol>
            <label htmlFor="address">Endereço</label>
            <Input
              type="text"
              name="address"
              id="address"
              placeholder="Endereço completo"
              defaultValue={props.userData?.address}
            />
          </InputDiv>

          <InputDiv flexCol>
            <label htmlFor="city">Cidade</label>
            <Input
              type="text"
              name="city"
              id="city"
              placeholder="Cidade"
              defaultValue={props.userData?.city}
            />
          </InputDiv>
        </div>
        <div className="flex space-x-20 w-full items-center justify-center">
          <InputDiv flexCol>
            <label htmlFor="state">Estado</label>
            <Input
              type="text"
              name="state"
              id="state"
              placeholder="Estado"
              defaultValue={props.userData?.state}
            />
          </InputDiv>

          <InputDiv flexCol>
            <label htmlFor="country">País</label>
            <Input
              type="text"
              name="country"
              id="country"
              placeholder="País"
              defaultValue={props.userData?.country}
            />
          </InputDiv>
        </div>
        <Button className="self-center mt-5">Atualizar Dados</Button>
      </form>
    </div>
  )
}
