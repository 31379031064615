import { Subtext } from '../../Components/Screen/Text/Body'
import { Heading } from '../../Components/Screen/Text/Heading'

export const NewCollection = ({ arts, handleInfos }) => {
  const lastArts =
    arts?.length > 4 ? arts?.slice(arts.length - 4, arts.length) : arts

  const isEmpty = lastArts?.length === 0

  return (
    <div className="w-full gap-8 flex flex-col text-white select-none">
      <Heading className="w-full">
        Novas <Heading className="text-laranja">Fazendas</Heading>
      </Heading>

      {isEmpty ? (
        <div className="flex justify-center items-center w-full h-96">
          <Heading className="text-white">Nenhuma fazenda encontrada</Heading>
        </div>
      ) : (
        <div className="grid items-center justify-center grid-cols-2 gap-4">
          {lastArts?.map((art) => (
            <div key={art?.withoutqr} className=" 2xl:px-40">
              <div className=" flex flex-col items-center justify-center relative">
                <img
                  src={art?.withoutqr}
                  alt={art?.name}
                  onClick={() => handleInfos(art)}
                  className="rounded h-40  w-full object-cover object-center  lg:h-64 cursor-pointer "
                  loading="eager"
                />
                <div className="bottom-0  flex text-center backdrop-blur-md bg-cinzaescuro/50 absolute w-full p-2 h-12   justify-between items-center font-light">
                  <Subtext>{art?.name}</Subtext>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
