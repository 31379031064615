import 'swiper/css'
import 'swiper/css/grid'
import { BackgroundImage } from './BackgroundImage'
import { UserInfo } from './UserInfo'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid } from 'swiper'
import { Card } from '../Mobile/Collection'
import NFTList from '../NFTList'
import Footer from '../../../../Components/Footer/Index'
import { Link, useNavigate } from 'react-router-dom'
import NFTCard from '../NFTCard'
import { ListCollection } from './ListCollection'
import { FadersHorizontal, GridFour, List } from '@phosphor-icons/react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { useGlobalContext } from '../../../../Context/GlobalContext'

export const DesktopPage = (props) => {
  const { userData: data } = useGlobalContext()
  const navigate = useNavigate()
  const {
    options,
    setOptions,
    showType,
    setShowType,
    allNfts,
    nftsCreated,
    maxMediaQuery,
    collection,
  } = props

  function handleInfos(art) {
    navigate(`/farms/${art?.name}`, { state: { art } })
  }

  return (
    <div className="flex flex-col justify-between relative">
      <div className="flex-grow min-h-[1550px]">
        <BackgroundImage {...props} />

        <UserInfo {...props} />

        <div className="absolute left-[26rem] top-[17rem] w-[35rem] lg:w-[w-45rem] 2xl:w-[65rem] xl:w-[50rem] flex flex-col gap-6">
          <div className="flex gap-3 text-lg w-full justify-between">
            <span
              className={`cursor-pointer ${
                options === 'NFTCollected' ? 'text-laranja' : 'text-white'
              }`}
              onClick={() => setOptions('NFTCollected')}
            >
              Coleção de NFTs
            </span>
            <span
              className={`cursor-pointer ${
                options === 'NFTCreated' ? 'text-laranja' : 'text-white'
              }`}
              onClick={() => setOptions('NFTCreated')}
            >
              NFT Criados
            </span>
            <span
              className={`cursor-pointer ${
                options === 'CollectionManagement'
                  ? 'text-laranja'
                  : 'text-white'
              }`}
              onClick={() => setOptions('CollectionManagement')}
            >
              Gerenciamento de coleção
            </span>
            <Link to="/farm/register">Registrar Fazenda</Link>
          </div>

          <div className="flex gap-3  w-full  justify-between">
            <div className="flex gap-5">
              <GridFour
                size={24}
                className={`cursor-pointer ${
                  showType === 'Card' ? 'text-white' : 'text-cinza'
                }`}
                onClick={() => setShowType('Card')}
              />

              <List
                size={24}
                className={`cursor-pointer ${
                  showType === 'List' ? 'text-white ' : 'text-cinza'
                }`}
                onClick={() => setShowType('List')}
              />
            </div>

            <Text className="flex flex-row items-center gap-2 ">
              Filtrar por <FadersHorizontal size={24} />
            </Text>
          </div>

          <div className="flex flex-col gap-6 h-full w-full">
            {showType === 'Card' ? (
              <>
                <div className="flex flex-col gap-6">
                  {options === 'NFTCollected' && (
                    <Swiper
                      slidesPerView={maxMediaQuery ? 2 : 3}
                      spaceBetween={30}
                      grid={{ rows: 2, fill: 'row' }}
                      modules={[Grid]}
                      className="mySwiper w-full h-full"
                    >
                      {allNfts?.map((item) => (
                        <SwiperSlide key={item.token_id} className="">
                          <NFTCard nft={item} />
                        </SwiperSlide>
                      ))}
                      {allNfts?.length === 0 && (
                        <Text>Nenhum NFT encontrado</Text>
                      )}
                    </Swiper>
                  )}
                  {options === 'NFTCreated' && (
                    <Swiper
                      slidesPerView={maxMediaQuery ? 2 : 3}
                      spaceBetween={30}
                      grid={{ rows: 2, fill: 'row' }}
                      modules={[Grid]}
                      className="mySwiper w-full h-full"
                    >
                      {nftsCreated?.map((item) => (
                        <SwiperSlide key={item.token_id} className="">
                          <NFTCard nft={item} />
                        </SwiperSlide>
                      ))}
                      {nftsCreated?.length === 0 && (
                        <Text>Nenhum NFT encontrado</Text>
                      )}
                    </Swiper>
                  )}
                  {options === 'CollectionManagement' && (
                    <Swiper
                      slidesPerView={maxMediaQuery ? 2 : 3}
                      spaceBetween={30}
                      grid={{ rows: 2, fill: 'row' }}
                      modules={[Grid]}
                      className="mySwiper w-full h-full"
                    >
                      {collection?.map((item, index) => (
                        <SwiperSlide key={index} className="">
                          <Card
                            item={item}
                            handleInfos={handleInfos}
                            data={data}
                          />
                        </SwiperSlide>
                      ))}
                      {collection?.length === 0 && (
                        <Text>Nenhuma coleção encontrada</Text>
                      )}
                    </Swiper>
                  )}
                </div>
              </>
            ) : (
              showType === 'List' && (
                <>
                  <div className="flex flex-col gap-6">
                    {options === 'NFTCollected' && (
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        grid={{ rows: 6, fill: 'row' }}
                        modules={[Grid]}
                        className="mySwiper w-full h-full"
                      >
                        {allNfts?.map((item) => (
                          <SwiperSlide key={item.token_id} className="">
                            <NFTList nft={item} />
                          </SwiperSlide>
                        ))}
                        {allNfts?.length === 0 && (
                          <Text>Nenhum NFT encontrado</Text>
                        )}
                      </Swiper>
                    )}
                    {options === 'NFTCreated' && (
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        grid={{ rows: 6, fill: 'row' }}
                        modules={[Grid]}
                        className="mySwiper w-full h-full"
                      >
                        {nftsCreated?.map((item) => (
                          <SwiperSlide key={item.token_id} className="">
                            <NFTList nft={item} />
                          </SwiperSlide>
                        ))}
                        {nftsCreated?.length === 0 && (
                          <Text>Nenhum NFT encontrado</Text>
                        )}
                      </Swiper>
                    )}
                    {options === 'CollectionManagement' && (
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        grid={{ rows: 6, fill: 'row' }}
                        modules={[Grid]}
                        className="mySwiper w-full h-full"
                      >
                        {collection?.map((item, index) => (
                          <SwiperSlide key={index} className="">
                            <ListCollection
                              item={item}
                              handleInfos={handleInfos}
                            />
                          </SwiperSlide>
                        ))}
                        {collection?.length === 0 && (
                          <div>Nenhuma coleção encontrada</div>
                        )}
                      </Swiper>
                    )}
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
