import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { ModalStep5 } from './modal'

export default function ArtRegisterStep5Page({
  setStep,
  step5,
  setStep5,
  handleSubmit,
  isEmailSended,
  setIsEmailSended,
  step2,
}) {
  const [analisys, setAnalisys] = useState(step5?.analisys || 'AI')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleAiSubmit = async (event) => {
    event.preventDefault()
    setStep5((prevState) => ({
      ...prevState,
      analisys,
    }))

    if (analisys === 'AI') {
      setIsModalOpen(true)
    } else {
      await handleSubmit(event)
      setStep(6)
    }
  }

  const handleAiChange = (event) => {
    setAnalisys(event.target.value)
    setStep5((prevState) => ({
      ...prevState,
      analisys: event.target.value,
    }))
  }

  useEffect(() => {
    setAnalisys(step5?.analisys)
  }, [analisys, setStep5, step5?.analisys])

  return (
    <section className="flex flex-col items-center md:px-20">
      {isModalOpen && (
        <div className="relative flex items-center justify-center w-full">
          <ModalStep5
            setIsModalOpen={setIsModalOpen}
            setStep={setStep}
            setStep5={setStep5}
            handleSubmit={handleSubmit}
            isEmailSended={isEmailSended}
            setIsEmailSended={setIsEmailSended}
            analisys={analisys}
            step2={step2}
          />
        </div>
      )}

      <div className="flex items-center w-full mt-10">
        <ArrowLeft
          size={32}
          weight="bold"
          className="text-verdeClaro hover:cursor-pointer"
          onClick={() => setStep(4)}
        />
        <Heading as="h1" className="ml-4 text-verdeClaro">
          Inteligência Artificial
        </Heading>
      </div>

      <Text
        as={'p'}
        className="self-start mt-4 font-light text-branco lg:w-2/3"
      >
        Este pedido ocorre por meio da demanda dos comitês de validação, você
        será informado sobre essa necessidade. Se quiser, pode fazer a análise
        independente agora, basta selecionar abaixo:
      </Text>

      <form className="w-full mt-8" onSubmit={(e) => handleAiSubmit(e)}>
        <label className="flex items-center justify-start mb-4 font-light text-branco lg:text-xl">
          <input
            type="radio"
            name="analisys"
            id="AI"
            value="AI"
            required
            onChange={(e) => {
              handleAiChange(e)
            }}
            className="mr-3 radio radio-primary"
            checked={analisys === 'AI'}
          />
          <Text className={'font-semibold'}>Eu quero fazer a Análise I.A.</Text>
        </label>

        <label className="flex items-center justify-start mb-4 font-light text-branco lg:text-xl">
          <input
            type="radio"
            name="analisys"
            id="Committee"
            value="Committee"
            onChange={(e) => {
              handleAiChange(e)
            }}
            required
            className="mr-3 radio radio-secondary"
            checked={analisys === 'Committee'}
          />
          <Text className={'font-semibold'}>
            Eu quero aguardar a análise do comitê
          </Text>
        </label>

        <div className="w-full flex flex-row-reverse items-end lg:mt-72 lg:pr-20">
          <button>
            <ArrowRight
              size={48}
              weight="bold"
              className="text-verdeClaro hover:cursor-pointer"
            />
          </button>
        </div>
      </form>
    </section>
  )
}
