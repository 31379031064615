import { ArrowRight } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { Text } from '../../../Components/Screen/Text/Body'

export const Provenance = () => {
  const navigate = useNavigate()
  return (
    <div
      className="items-center w-full gap-1 p-10 my-10 font-light cursor-pointer bg-cinzaescuro text-white rounded lg:text-xl lg:flex select-none"
      onClick={() => {
        navigate('/register')
      }}
    >
      <Text>
        Coloque{' '}
        <Text className="font-medium text-verdeClaro">
          {' '}
          seu nome na história da fazenda{' '}
        </Text>{' '}
        via blockchain e negocie
        <Text className="font-medium text-verdeClaro"> NFTs </Text> com
        <Text className="font-medium text-verdeClaro"> Prova de Origem</Text>!
      </Text>
      <i className="ml-2 lg:text-4xl text-verdeClaro">
        <ArrowRight size={24} />
      </i>
    </div>
  )
}
