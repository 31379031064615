import useMediaQuery from '../../../../../hooks/useMediaQuery'
import register from '../assets/registerandvali.png'
// import logo from '../assets/logo.png'
import collection from '../assets/collection.png'
import nftProvenance from '../assets/nftprovenance.png'
import specMarket from '../assets/specmarket.png'
import club from '../assets/club.png'
import metaverse from '../assets/metaverse.png'
import { Heading } from '../../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../../Components/Screen/Text/Body'
import { CowIcon } from '../../../../../assets/cow'

export const EcosystemMobile = () => {
  const mediaQuery2 = useMediaQuery('(min-width: 420px)')

  return (
    <section
      className="flex flex-col gap-6 items-center w-full font-extralight  justify-center px-4 "
      id="ecosystem"
    >
      <Heading className="text-laranjaClaro">
        Farmie <Heading>Ecossistema</Heading>
      </Heading>

      <div className="w-full items-center justify-center gap-4 flex flex-col">
        {/* <img src={logo} alt="logo" className="w-16" /> */}
        <CowIcon className="w-16 h-16" />
        <div className="flex flex-col items-start">
          <div
            className={`items-center ${
              mediaQuery2 ? '' : 'self-start'
            } flex tooltip gap-2`}
            data-tip="Para você registrar sua fazenda para sempre, com prova de origem e transparência."
          >
            <img className="w-16 rounded-full" src={register} alt="icon" />
            <Text>Certificação e Registro</Text>
          </div>

          <div
            className={`items-center ${
              mediaQuery2 ? '' : 'self-start'
            } flex tooltip gap-2 `}
            data-tip="Organize coleções físicas e digitais, dinâmicas para atender às vidas cotidianas de colecionadores e instituições."
          >
            <img src={collection} alt="" className="w-16 " />
            <Text>Gerencie sua coleção</Text>
          </div>

          <div
            className={`items-center ${
              mediaQuery2 ? '' : 'self-start'
            } flex tooltip gap-2 `}
            data-tip="Com histórico e documentos que provam autenticidade. Para sempre."
          >
            <img src={nftProvenance} alt="" className="w-16 " />
            <Text>NFT com prova de origem</Text>
          </div>

          <div
            className={`items-center ${
              mediaQuery2 ? '' : 'self-start'
            } flex tooltip gap-2 `}
            data-tip="Servir com a exclusividade necessária. Ao mesmo tempo, permitimos conexões com parceiros (API)."
          >
            <img src={specMarket} alt="" className="w-16 " />
            <Text>Marketplace especializado</Text>
          </div>

          <div
            className={`items-center ${
              mediaQuery2 ? '' : 'self-start'
            } flex tooltip gap-2 `}
            data-tip="Onde o mundo da fazenda interage e colabora para o desenvolvimento da cultura."
          >
            <img src={club} alt="" className="w-16 " />
            <Text>Farmie Club</Text>
          </div>

          <div
            className={`items-center ${
              mediaQuery2 ? '' : 'self-start'
            } flex tooltip gap-2`}
            data-tip="Em breve..."
          >
            <img src={metaverse} alt="" className="w-16 " />
            <Text>Metaverso</Text>
          </div>
        </div>
      </div>
    </section>
  )
}
