import { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { Text } from '../../../../Components/Screen/Text/Body'
import hideFarm from '../../../../utils/firebase/hideFarm'

export default function Collection() {
  const { userData: data } = useGlobalContext()
  const [userData, setUserData] = useState([])
  const [noArtworks, setNoArtworks] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      const { art } = data
      if (art) {
        const array = Object.values(art)
        setUserData(array)
      } else {
        setUserData([])
        setNoArtworks('Você ainda não possui nenhuma obra cadastrada.')
      }
    }
  }, [data])

  function handleInfos(art) {
    navigate(`/farms/${art?.title}`, { state: { art } })
  }

  return (
    <section className="py-6 px-4">
      <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 gap-5">
        {userData?.map((item, index) => (
          <Card item={item} handleInfos={handleInfos} key={index} data={data} />
        ))}
        {noArtworks && (
          <div className="w-full flex items-center text-white justify-center">
            {noArtworks}
          </div>
        )}
      </div>
    </section>
  )
}

export const Card = ({ item, handleInfos, data }) => {
  const [hide, setHide] = useState(item?.hide ?? false)
  const ocultar = !hide
  const art = data?.art
  return (
    <div className="flex flex-col relative h-96 select-none">
      <img
        src={item?.withoutqr}
        alt={item?.name}
        className="w-full  rounded-t-lg object-cover object-center h-96"
        loading="eager"
      />
      <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-preto" />
      <div className="flex flex-col absolute z-20 bottom-16  left-0 pl-2 text-xs justify-between text-cinzaclaro">
        <span
          onClick={() => handleInfos(item)}
          className="text-xl font-semibold text-branco cursor-pointer hover:text-laranja"
        >
          {item?.name}
        </span>
        <span>Reg.: {item?.matricula}</span>
        <span>PRA: {item?.pra}</span>
        <span>CAR: {item?.car}</span>
      </div>

      <div className="absolute bottom-0 z-30 flex flex-row text-branco font-normal justify-between w-full px-6 py-4 items-center bg-emerald-800 rounded-b-lg">
        <Text
          className="hover:cursor-pointer hover:text-laranja"
          onClick={() => {
            hideFarm(ocultar, art, item, setHide)
          }}
        >
          {hide ? 'Desocultar' : 'Ocultar'}
        </Text>

        <Text
          className="hover:cursor-pointer hover:text-laranja"
          onClick={() => handleInfos(item)}
        >
          Ver
        </Text>
      </div>
    </div>
  )
}
