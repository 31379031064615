import { ArrowRight } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { Subtext } from '../../../../Components/Screen/Text/Body'
import ImageOne from '../assets/image1.jpg'
import ImageTwo from '../assets/image2.jpg'
import MarketImage from '../assets/MARKET.png'

export const ImageContainer = () => {
  return (
    <>
      <div className="w-1/4">
        <img src={ImageOne} alt="Art left" className="rounded-l-2xl" />
        <Link to="/marketplace" className="hidden sm:flex gap-2">
          <Subtext>Marketplace </Subtext>
          <ArrowRight size={24} className="text-laranjaClaro" />
        </Link>
      </div>
      <div className="w-1/3">
        <img src={MarketImage} alt="Market" className="rounded-2xl" />
      </div>
      <div className="w-1/4">
        <Subtext className="hidden sm:flex text-white justify-end">
          Compre, venda e negocie!
        </Subtext>
        <img src={ImageTwo} alt="Art right" className="rounded-r-2xl" />
      </div>
    </>
  )
}
