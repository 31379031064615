export const SvgStep2 = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dddii_1383_9820)">
        <circle
          cx="24.105"
          cy="24.6753"
          r="17.105"
          fill="url(#paint0_linear_1383_9820)"
        />
      </g>
      <path
        d="M26.0211 24.7087C26.0211 25.7488 25.1779 26.5919 24.1378 26.5919C23.0977 26.5919 22.2546 25.7488 22.2546 24.7087C22.2546 23.6686 23.0977 22.8254 24.1378 22.8254C25.1779 22.8254 26.0211 23.6686 26.0211 24.7087Z"
        fill="white"
      />
      <path
        d="M30.349 24.4279C28.9942 21.7183 26.588 20.3145 24.1378 20.3145C21.6876 20.3145 19.2814 21.7183 17.9266 24.4279C17.8383 24.6047 17.8383 24.8127 17.9266 24.9894C19.2814 27.6991 21.6876 29.1029 24.1378 29.1029C26.588 29.1029 28.9942 27.6991 30.349 24.9894C30.4374 24.8127 30.4374 24.6047 30.349 24.4279ZM24.1378 27.8474C22.2742 27.8474 20.3701 26.83 19.1972 24.7087C20.3701 22.5874 22.2742 21.5699 24.1378 21.5699C26.0015 21.5699 27.9055 22.5874 29.0784 24.7087C27.9055 26.83 26.0015 27.8474 24.1378 27.8474Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_dddii_1383_9820"
          x="0.314798"
          y="0.88511"
          width="48.7688"
          height="48.7698"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.2284" dy="2.2284" />
          <feGaussianBlur stdDeviation="2.82264" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1383_9820"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.2284" dy="-2.2284" />
          <feGaussianBlur stdDeviation="2.2284" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1383_9820"
            result="effect2_dropShadow_1383_9820"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2.2284" dy="2.2284" />
          <feGaussianBlur stdDeviation="2.2284" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1383_9820"
            result="effect3_dropShadow_1383_9820"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1383_9820"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.14856" dy="-0.14856" />
          <feGaussianBlur stdDeviation="0.14856" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect4_innerShadow_1383_9820"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.14856" dy="0.14856" />
          <feGaussianBlur stdDeviation="0.14856" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_innerShadow_1383_9820"
            result="effect5_innerShadow_1383_9820"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1383_9820"
          x1="7"
          y1="7.57031"
          x2="41.21"
          y2="41.7803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F1F1F" />
          <stop offset="1" stopColor="#282828" />
        </linearGradient>
      </defs>
    </svg>
  )
}
