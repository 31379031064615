import axios from 'axios'
export const createMetadata = async (path, json) => {
  const options = {
    method: 'POST',
    url: 'https://deep-index.moralis.io/api/v2/ipfs/uploadFolder',
    headers: {
      accept: 'application/json',
      'X-API-Key': process.env.REACT_APP_moralis_apiKey
    },
    data: [{ path, content: json }],
  }
  try {
    const response = await axios.request(options)
    return response.data[0]?.path
  } catch (error) {
    console.error(error)
  }
}
