import 'primeicons/primeicons.css'
import { useEffect, useState } from 'react'
import { InputMask } from 'primereact/inputmask'
import {
  getDownloadURL,
  uploadBytesResumable,
  ref as refStorage,
} from 'firebase/storage'
import { auth, storage } from '../../../../firebase'

import { ArrowRight, File, FilePlus, Spinner, X } from '@phosphor-icons/react'
import { validateSize } from '../../../../utils/validateSizeFile'
import StorageClient from '../../../../utils/storageFile'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import {
  InputDiv,
  Input as InputMy,
} from '../../../../Components/Screen/Input/Text'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { getAddress } from '../../../../utils/address/getAddressByCEP'

export default function ArtRegisterStep2Page({
  userData,
  setStep2,
  setStep,
  farm,
  step2,
  setFarm,
}) {
  const data = userData?.artworks?.arts
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(farm?.name || '')
  const [cnpj, setCnpj] = useState(farm?.cnpj || '')
  const [zipCode, setZipCode] = useState(farm?.zipCode || '')
  const [address, setAddress] = useState(farm?.address || '')
  const [matricula, setMatricula] = useState(farm?.matricula || '')
  const [stateInscription, setStateInscription] = useState(
    farm?.stateInscription || '',
  )
  const [localization, setLocalization] = useState(farm?.localization || '')
  const [localization2, setLocalization2] = useState(farm?.localization2 || '')
  const [car, setCar] = useState(farm?.car || '')
  const [haveCar, setHaveCar] = useState(farm?.haveCar || false)
  const [pra, setPra] = useState(farm?.pra || '')
  const [havePra, setHavePra] = useState(farm?.havePra || false)
  const [rented, setRented] = useState(farm?.rented || false)
  const [rentedName, setRentedName] = useState(farm?.rentedName || '')
  const [rentedCpf, setRentedCpf] = useState(farm?.rentedCpf || '')
  const [withoutQR, setWithoutQR] = useState(farm?.withoutQR || '')
  const [farms, setFarms] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    setFarms(data?.map((farme) => farme) || [])
  }, [data])

  const handleSubmit = async (event) => {
    event.preventDefault()

    setLoading(true)

    if (
      step2.withoutQR !== withoutQR ||
      step2.name !== name ||
      step2.address !== address ||
      step2.matricula !== matricula ||
      step2.car !== car ||
      step2.pra !== pra ||
      step2.cnpj !== cnpj ||
      step2.zipCode !== zipCode ||
      step2.stateInscription !== stateInscription ||
      step2.localization !== localization ||
      step2.localization2 !== localization2 ||
      step2.haveCar !== haveCar ||
      step2.havePra !== havePra ||
      step2.rented !== rented ||
      step2.rentedName !== rentedName ||
      step2.rentedCpf !== rentedCpf
    ) {
      const frontqr = await new StorageClient().storeFiles(withoutQR)
      const metadata = {
        contentType: 'image/jpeg',
      }
      let downloadURL
      const teste = await new Promise((resolve, reject) => {
        const storageRef = refStorage(
          storage,
          'images/' +
          auth?.currentUser?.uid +
          '/' +
          name +
          car +
          '/' +
          withoutQR?.name,
        )
        const uploadTask = uploadBytesResumable(storageRef, withoutQR, metadata)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.info('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.info('Upload is paused')
                break
              case 'running':
                console.info('Upload is running')
                break
              default:
                break
            }
          },
          (error) => {
            reject(error)
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              downloadURL = url
              resolve(downloadURL)
            })
          },
        )
      })
      if (teste) {
        const newFarm = {
          name,
          address,
          matricula,
          car,
          pra,
          frontqr,
          withoutqr: downloadURL,
          withoutQR,
          cnpj,
          zipCode,
          stateInscription,
          localization,
          rented,
          rentedName,
          rentedCpf,
          localization2,
          haveCar,
          havePra,
        }

        setFarm({ ...newFarm })
        const newFarms = [...farms, newFarm]
        setStep2(...newFarms)
      }
    } else {
      setStep2(step2)
      setStep(4)
    }
    setLoading(false)
    setStep(4)
  }

  useEffect(() => {
    if (!haveCar) {
      setCar('')
    }
    if (!havePra) {
      setPra('')
    }
    if (!rented) {
      setRentedName('')
      setRentedCpf('')
    }
  }, [haveCar, havePra, rented])

  if (loading) return <Loading />

  return (
    <section
      className="flex flex-col text-white w-full max-w-7xl mx-auto min-h-[calc(100vh-80px)]"
      id="container"
    >
      <Heading className={'text-verdeClaro text-center lg:pt-6'}>
        Dados da propriedade
      </Heading>
      <form
        onSubmit={handleSubmit}
        className="p-4 flex flex-col md:flex-row gap-3 lg:grid lg:grid-cols-2 lg:gap-4 lg:px-20 w-full lg:pt-10"
      >
        <div className="flex flex-col gap-3 w-full">
          <Input label="Nome da Fazenda" value={name} onChange={setName} />
          <InputMasked
            label="CNPJ"
            value={cnpj}
            onChange={setCnpj}
            mask="99.999.999/9999-99"
          />
          <Input label="CEP" value={zipCode} onChange={setZipCode}
            onBlur={() => {
              const zip = zipCode.replaceAll('-', '').replaceAll('_', '').slice(0, 8)
              setZipCode(zip)
              if (zip.length === 8) {
                getAddress(zip).then((res) => {
                  setLocalization(res?.lat)
                  setLocalization2(res?.lng)
                  setAddress(res?.address)
                }).catch((err) => {
                  console.error(err)
                })
              }
            }}
          />
          <Input label="Endereço" value={address} onChange={setAddress} />
          <Input label="Registro" value={matricula} onChange={setMatricula} />
          <Input
            label="Registro de Estado"
            value={stateInscription}
            onChange={setStateInscription}
          />
          <Input
            label="Latitude"
            value={localization}
            onChange={setLocalization}
          />

          <Input
            label="Longitude"
            value={localization2}
            onChange={setLocalization2}
          />
        </div>

        <div className="flex flex-col gap-3 w-full z-30">
          <div className="flex items-center">
            <Input
              label="CAR"
              value={car}
              onChange={setCar}
              disabled={!haveCar}
            />
            <div className="flex flex-col items-center w-20 text-xs">
              <label htmlFor="haveCar">CAR</label>
              <input
                id="haveCar"
                type="checkbox"
                className="checkbox checkbox-primary"
                checked={haveCar}
                onChange={() => setHaveCar(!haveCar)}
              />
            </div>
          </div>
          <div className="flex items-center">
            <Input
              label="PRA"
              value={pra}
              onChange={setPra}
              disabled={!havePra}
            />
            <div className="flex flex-col items-center w-20 text-xs">
              <label htmlFor="havePra">PRA</label>
              <input
                id="havePra"
                className="checkbox checkbox-primary"
                type="checkbox"
                checked={havePra}
                onChange={() => setHavePra(!havePra)}
              />
            </div>
          </div>

          <div className="flex items-center">
            <Input
              label="Nome do Arrendatário"
              value={rentedName}
              onChange={setRentedName}
              disabled={!rented}
            />
            <div className="flex flex-col items-center w-20 text-xs">
              <label htmlFor="arrendado">Arrendado</label>
              <input
                id="arrendado"
                className="checkbox checkbox-primary"
                type="checkbox"
                checked={rented}
                onChange={() => setRented(!rented)}
              />
            </div>
          </div>
          <div className="flex items-center">
            <InputMasked
              label="CPF do Arrendatário"
              value={rentedCpf}
              onChange={setRentedCpf}
              disabled={!rented}
              mask="999.999.999-99"
            />
            <div className="flex flex-col items-center w-20 text-xs">
              <label htmlFor="arrendado">Arrendado</label>
              <input
                id="arrendado"
                className="checkbox checkbox-primary"
                type="checkbox"
                checked={rented}
                onChange={() => setRented(!rented)}
              />
            </div>
          </div>

          <div className="uploads lg:mx-auto">
            {withoutQR && (
              <span
                className="absolute top-1 right-2 text-laranja hover:cursor-pointer"
                onClick={() => setWithoutQR(null)}
              >
                <X size={24} />
              </span>
            )}

            <i className="relative p-0 text-3xl text-laranja">
              <label
                htmlFor="fileWithoutQR"
                className="cursor-pointer"
                onClick={() => setError(null)}
              >
                {withoutQR ? <File size={40} /> : <FilePlus size={40} />}
              </label>
              <input
                type="file"
                id="fileWithoutQR"
                accept="image/jpg, image/png, image/jpeg,  image/tiff"
                className=" hidden"
                onChange={(e) =>
                  validateSize(e.target.files[0], setError, setWithoutQR)
                }
              />
            </i>
            {error && <p className="text-red-500">{error}</p>}
            <div className="pt-2 flex flex-col items-center" id="end">
              <div className="flex flex-col text-center select-none">
                <span className=" mb-2 rounded-2xl p-2 bg-laranja text-preto">
                  Foto da fazenda
                </span>
              </div>
              {withoutQR && (
                <div className="flex flex-col items-center w-32 justify-center ">
                  <img
                    src={URL.createObjectURL(withoutQR)}
                    alt="withoutQR"
                    className="w-32 h-32"
                    loading="eager"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="text-right lg:self-end  w-full ">
            {loading ? (
              <div className="flex items-center gap-2 text-laranja">
                <Spinner size={24} className="animate-spin text-verdeClaro" />
                Enviando, aguarde...
              </div>
            ) : (
              <button
                type="submit"
                className="text-5xl text-laranja"
                disabled={!withoutQR}
              >
                <ArrowRight size={48} weight="bold" />
              </button>
            )}
          </div>
        </div>
      </form>
    </section>
  )
}

const Input = ({ label, value, onChange, disabled , ...rest}) => {
  return (
    <div className="w-full mx-auto max-w-lg  z-30">
      <label htmlFor={label} className="text-lg text-branco">
        {label}
      </label>
      <InputDiv>
        <InputMy
          type="text"
          disabled={disabled}
          name={label}
          id={label}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="w-full text-sm bg-transparent placeholder:font-extralight placeholder:text-branco focus:outline-none"
          required
          {...rest}
        />
      </InputDiv>
    </div>
  )
}

const InputMasked = ({ label, value, onChange, mask, disabled }) => {
  return (
    <div className="w-full mx-auto max-w-lg  z-30">
      <label htmlFor={label} className="text-lg text-branco">
        {label}
      </label>
      <InputDiv>
        <InputMask
          disabled={disabled}
          mask={mask}
          name={label}
          id={label}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="w-full pb-2 text-sm bg-transparent placeholder:font-extralight placeholder:text-branco focus:outline-none"
          required
        />
      </InputDiv>
    </div>
  )
}
