import './assets/page.css'
import { ref, update } from 'firebase/database'
import { useState } from 'react'
import ArtRegisterCreateNFTPage from './CreateNFT/ArtRegisterCreateNFT'
import ArtRegisterStep1Page from './Step1/ArtRegisterStep1'
import ArtRegisterStep2Page from './Step2/ArtRegisterStep2'
import ArtRegisterStep4Page from './Step4/ArtRegisterStep4'
import ArtRegisterStep5Page from './Step5/ArtRegisterStep5'
import ArtRegisterSuccessPage from './Success/ArtRegisterSuccess'
import { Step0ArtRegister } from './Step0/ArtRegisterStep0'
import { NotLogged } from '../../Error/NotLogged'
import { auth, db } from '../../../firebase'
import { useGlobalContext } from '../../../Context/GlobalContext'
import { CircleLight } from '../../../Components/Screen/circleLight'

export default function FarmRegisterPage() {
  const [step, setStep] = useState(0)

  const [step1, setStep1] = useState({})
  const [artTickets, setArtTickets] = useState('')
  const [mintTickets, setMintTickets] = useState('')
  const [totalAmount, setTotalAmount] = useState('')
  const [cupom, setCupom] = useState('')

  const [step2, setStep2] = useState({})
  const [step2Done, setStep2Done] = useState(false)
  const [farm, setFarm] = useState({})

  const [step4, setStep4] = useState({})
  const [exhibitionDone, setExhibitionDone] = useState(false)
  const [certificateDone, setCertificateDone] = useState(false)
  const [bookDone, setBookDone] = useState(false)

  const [step5, setStep5] = useState({})
  const [isEmailSended, setIsEmailSended] = useState(false)
  const [nft, setNft] = useState({})

  const userId = auth?.currentUser?.uid

  const { userData, updateUser } = useGlobalContext()

  async function saveFarmInFirebase(artToSave) {
    try {
      const randomId = Math.random().toString(36).substring(2, 15)
      await update(ref(db, 'users/' + userId + '/art/' + randomId), {
        ...artToSave,
      })
      await update(ref(db, 'users/' + userId), {
        artTicket: userData?.artTicket ? parseInt(userData?.artTicket) - 1 : 0,
      })
      await updateUser()
    } catch (err) {
      console.log(err)
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const ArtRegister = {
      ...step1,
      ...step2,
      expertizations: [],
      ...step4,
      ...step5,
      ...nft,
      userId,
    }

    await saveFarmInFirebase(ArtRegister)
  }

  return (
    <>
      {userId ? (
        <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
          <CircleLight />
          <CircleLight left position={'bottom-0'} />

          <div>
            {step === 0 && (
              <Step0ArtRegister userData={userData} setStep={setStep} />
            )}
            {step === 1 && (
              <ArtRegisterStep1Page
                setStep={setStep}
                setStep1={setStep1}
                artTickets={artTickets}
                mintTickets={mintTickets}
                setArtTickets={setArtTickets}
                setMintTickets={setMintTickets}
                totalAmount={totalAmount}
                setTotalAmount={setTotalAmount}
                cupom={cupom}
                setCupom={setCupom}
              />
            )}
            {step === 2 && (
              <ArtRegisterStep2Page
                userData={userData}
                farm={farm}
                setFarm={setFarm}
                setStep2={setStep2}
                setStep={setStep}
                step2={step2}
                setStep2Done={setStep2Done}
                step2Done={step2Done}
              />
            )}

            {step === 4 && (
              <ArtRegisterStep4Page
                step4={step4}
                exhibitionDone={exhibitionDone}
                certificateDone={certificateDone}
                bookDone={bookDone}
                setStep={setStep}
                setStep4={setStep4}
                setExhibitionDone={setExhibitionDone}
                setCertificateDone={setCertificateDone}
                setBookDone={setBookDone}
                step2={step2}
              />
            )}
            {step === 5 && (
              <ArtRegisterStep5Page
                step5={step5}
                setStep={setStep}
                setStep5={setStep5}
                handleSubmit={handleSubmit}
                userData={userData}
                isEmailSended={isEmailSended}
                setIsEmailSended={setIsEmailSended}
                step2={step2}
              />
            )}
            {step === 6 && (
              <ArtRegisterCreateNFTPage
                setStep={setStep}
                step={step}
                setNft={setNft}
                nft={nft}
                userData={userData}
                step2={step2}
                step4={step4}
              />
            )}
            {step === 7 && <ArtRegisterSuccessPage />}
          </div>
        </div>
      ) : (
        <NotLogged />
      )}
    </>
  )
}
