import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'

export const TextLeft = () => {
  return (
    <>
      <Heading>
        <Heading className={'text-laranjaClaro'}>Rastreabilidade</Heading> e{' '}
        <Heading className={'text-laranjaClaro'}>certificação</Heading> de
        commodities para sua fazenda e safras!
      </Heading>
      <Subheading>
        Seu caminho para comercialização{' '}
        <Subheading className={'text-laranjaClaro'}>
          compliance com ESG
        </Subheading>
      </Subheading>
      <Text>
        Fácil, imediato, transparente e validado pelo{' '}
        <Text className={'text-laranjaClaro'}>Blockchain</Text>.
      </Text>
    </>
  )
}
