import axios from 'axios'

export const getUserNfts = async (address) => {
  const options = {
    method: 'GET',
    url: `https://deep-index.moralis.io/api/v2/${address}/nft`,
    params: {
      chain: process.env.REACT_APP_blockchain_chain || '0x13882',
      format: 'decimal',
      token_addresses: process.env.REACT_APP_nft_contract,
      normalizeMetadata: 'false',
    },
    headers: {
      accept: 'application/json',
      'X-API-Key': process.env.REACT_APP_moralis_apiKey,
    },
  }

  return axios
    .request(options)
    .then(function (response) {
      return response?.data?.result
    })
    .catch(function (error) {
      console.error(error)
    })
}
