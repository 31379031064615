import { Subtext, Text } from '../../../../Components/Screen/Text/Body'

export const CountStats = () => {
  return (
    <section className="flex items-center justify-around sm:px-2 md:px-40  bg-black/20 backdrop-blur py-5">
      <Text className={'flex flex-col items-center justify-center'}>
        Fazendas
        <Subtext className="font-semibold text-laranjaClaro">+100</Subtext>
      </Text>
      <Text className={'flex flex-col items-center justify-center'}>
        Em Análise
        <Subtext className="font-semibold text-laranjaClaro">+70</Subtext>
      </Text>
      <Text className={'flex flex-col items-center justify-center'}>
        Certificados ESG
        <Subtext className="font-semibold text-laranjaClaro">+50</Subtext>
      </Text>
      <Text className={'flex flex-col items-center justify-center'}>
        TONs trocados
        <Subtext className="font-semibold text-laranjaClaro">+20.000</Subtext>
      </Text>
    </section>
  )
}
