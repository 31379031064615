import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'

export const TextLeft = () => {
  return (
    <>
      <Heading>
        <Heading className={'text-verdeClaro'}>Rastreabilidade</Heading> e{' '}
        <Heading className={'text-verdeClaro'}>certificação</Heading> ambiental para sua produção!
      </Heading>
      <Subheading>
        Operamos suas{' '}
        <Subheading className={'text-verdeClaro'}>
          toneladas
        </Subheading> entre revendas, trades, cooperativas e outros participantes.
      </Subheading>
      <Text>
        Fácil, imediato, transparente e validado pelo{' '}
        <Text className={'text-verdeClaro'}>Blockchain</Text>.
      </Text>
    </>
  )
}
