export const InputDiv = ({ children, flexCol }) => (
  <div
    className={`flex ${flexCol ? 'flex-col' : 'flex-row'
      } border-b gap-2 w-full`}
  >
    {children}
  </div>
)

export const Input = ({ ...props }) => (
  <input
    {...props}
    className="bg-transparent w-full focus:outline-none text-gray-50"
  />
)
