import 'primereact/resources/primereact.min.css'

import { useState } from 'react'
import { Calendar } from 'primereact/calendar'
import { InputNumber } from 'primereact/inputnumber'
import { Chips } from 'primereact/chips'
import { Dropdown } from 'primereact/dropdown'
import { Check, WarningCircle, X } from '@phosphor-icons/react'
import StorageClient from '../../../../utils/storageFile'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Input, InputDiv } from '../../../../Components/Screen/Input/Text'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import seeds from './assets/seeds.json'

export default function CropDetails({ props }) {
  const {
    certificateModal,
    setCertificateModal,
    step4,
    setStep4,
    certificateDone,
    setCertificateDone,
    step2,
  } = props

  const [nParcels, setNParcels] = useState(step4?.cropDetails?.nParcels || '')
  const [parcelId, setParcelId] = useState(step4?.cropDetails?.parcelId || '')

  const [cropType, setCropType] = useState(step4?.cropDetails?.cropType || '')
  const [seedType, setSeedType] = useState(step4?.cropDetails?.seedType || '')
  const [harvestYear, setHarvestYear] = useState(
    step4?.cropDetails?.harvestYear || '',
  )
  const [yieldEstimation, setYieldEstimation] = useState(
    step4?.cropDetails?.yieldEstimation || '',
  )
  const [certificateFile, setCertificateFile] = useState(
    step4?.cropDetails?.files || null,
  )
  const localization = step2?.localization || ''
  const localization2 = step2?.localization2 || ''

  const [addLoading, setAddLoading] = useState(false)

  const handleModal = () => {
    setCertificateModal(!certificateModal)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    setAddLoading(true)

    const newDate = new Date(harvestYear).getFullYear().toString()
    const parcelGeolocation = {
      latitude: localization,
      longitude: localization2,
    }
    const files = certificateFile
    if (files) {
      const promises = []
      for (const element of files) {
        promises.push({
          url: await new StorageClient().storeFiles(element),
          name: element?.name,
        })
      }
      const urls = await Promise.all(promises)

      const cropDetails = {
        nParcels,
        parcelId,
        parcelGeolocation,
        cropType,
        seedType,
        harvestYear: newDate,
        yieldEstimation,
        files,
        file: urls || [],
      }
      setCertificateDone(true)
      setStep4((prevState) => ({ ...prevState, cropDetails }))
      setAddLoading(false)
      setCertificateModal(false)
    } else {
      const cropDetails = {
        nParcels,
        parcelId,
        parcelGeolocation,
        cropType,
        seedType,
        harvestYear: newDate,
        yieldEstimation,
        files: [],
        file: [],
      }
      setCertificateDone(true)
      setStep4((prevState) => ({ ...prevState, cropDetails }))
      setAddLoading(false)
      setCertificateModal(false)
    }
  }

  return (
    <>
      <div className="flex items-center">
        <Heading className=" cursor-pointer text-branco" onClick={handleModal}>
          Detalhes do cultivo
        </Heading>

        <i
          onClick={handleModal}
          className={`ml-2 ${
            certificateDone ? 'text-verdeClaro' : ' text-warning'
          } text-4xl hover:cursor-pointer`}
        >
          {certificateDone ? <Check size={36} /> : <WarningCircle size={36} />}
        </i>
      </div>
      {certificateModal && (
        <div className="modal modal-open backdrop-blur">
          <div className="relative modal-box bg-preto border border-gray-500">
            {!addLoading && (
              <X
                size={32}
                onClick={handleModal}
                className="absolute  text-verdeClaro right-2 top-2 hover:cursor-pointer z-30"
              />
            )}
            <Subheading as="h1" className=" text-verdeClaro mb-4 text-center">
              Detalhes do cultivo
            </Subheading>
            {addLoading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <InputCrop
                  name={'Número de Lotes'}
                  value={nParcels}
                  setValue={setNParcels}
                />
                <div className="w-full flex flex-col ">
                  <Text as="label" htmlFor={'Parcel IDs'}>
                    ID dos lotes (separados por vírgula)
                  </Text>
                  <Chips
                    value={parcelId}
                    onChange={(e) => setParcelId(e.value)}
                    separator=","
                    className="calendarInput"
                  />
                </div>

                <div className="w-full flex flex-col ">
                  <Text as="label" htmlFor={'Crop Type'}>
                    Tipo de Cultivo
                  </Text>
                  <Dropdown
                    value={cropType}
                    options={seeds}
                    onChange={(e) => setCropType(e.value)}
                    optionLabel="name"
                    editable
                    className="calendarInput"
                  />
                </div>
                <InputCrop
                  name={'Tipo de Semente'}
                  value={seedType}
                  setValue={setSeedType}
                />
                <div className="w-full flex flex-col ">
                  <Text as="label" htmlFor={'Harvest Year'}>
                    Ano da Colheita
                  </Text>
                  <Calendar
                    id="yearpicker"
                    name="yearpicker"
                    value={harvestYear}
                    onChange={(e) => setHarvestYear(e.value)}
                    view="year"
                    dateFormat="yy"
                    className="calendarInput"
                    minDate={new Date()}
                  />
                </div>
                <div className="w-full flex flex-col ">
                  <Text as="label" htmlFor={'Yield Estimation'}>
                    Estimativa de Rendimento (Tons)
                  </Text>
                  <InputNumber
                    value={yieldEstimation}
                    onValueChange={(e) => setYieldEstimation(e.value)}
                    suffix=" Tons"
                    min={1}
                    className="calendarInput"
                  />
                </div>
                <div className="flex my-4 flex-col">
                  {!certificateFile && (
                    <>
                      <input
                        type="file"
                        id="documentosCultivo"
                        multiple
                        accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
                        className="hidden"
                        onChange={(e) => setCertificateFile(e.target.files)}
                      />
                      <ButtonOutline
                        as="label"
                        htmlFor="documentosCultivo"
                        className="cursor-pointer mb-2 max-w-fit self-center"
                      >
                        Upload de Documentos
                      </ButtonOutline>
                    </>
                  )}
                  {certificateFile && certificateFile?.length > 0 && (
                    <div className="flex items-center justify-center gap-1">
                      <div className="text-xs text-center">
                        {certificateFile?.length || '0'} arquivo
                        {certificateFile?.length > 1 ? 's' : ''} selecionado
                        {certificateFile?.length > 1 ? 's' : ''}
                      </div>
                      <X
                        size={28}
                        onClick={() => setCertificateFile(null)}
                        className="text-error cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-center">
                  <ButtonOutline type="submit" disabled={addLoading}>
                    {addLoading ? 'Carregando...' : 'Salvar'}
                  </ButtonOutline>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const InputCrop = ({ name, value, setValue }) => {
  return (
    <>
      <Text as={'label'} htmlFor={name}>
        {name}
      </Text>
      <InputDiv>
        <Input
          id={name}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          required
        />
      </InputDiv>
    </>
  )
}
