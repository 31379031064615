import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep4 } from '../assets/SvgStep4'

export const Step4 = ({ mediaQuery }) => {
  return (
    <article className="flex flex-col gap-4 my-10 lg:flex-row lg:justify-between lg:items-center">
      {mediaQuery && (
        <img
          src="https://images.unsplash.com/photo-1620200423727-8127f75d7f53?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          alt="art"
          className="w-1/3 h-[20rem]  rounded-lg"
          loading="eager"
        />
      )}
      <div className="flex flex-col gap-3">
        <Text className="flex flex-row items-center gap-4">
          <SvgStep4 />
          <Text className="flex flex-col ">
            <Text className="flex flex-col text-white">
              Etapa 4
              <Heading className="text-azul"> Inteligência Artificial</Heading>
            </Text>
          </Text>
        </Text>
        <ul className="px-6 font-medium list-disc lg:text-lg">
          <li>
            <Text>
              Estudo por Inteligência Artificial para reconhecer os padrões da fazenda e de produção para redução de risco de embargos e atuaçoes
            </Text>
          </li>
          <li>
            <Text>Utilizamos algoritmos e aprendizado de máquina para análise de alarmes de deforestamento</Text>
          </li>
          <li>
            <Text>Algoritmo realiza estudos multi espectral e outros métodos científicos de análise para proteger a safra</Text>
          </li>
        </ul>
      </div>
    </article>
  )
}
