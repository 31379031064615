import { ArrowRight } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { CountStats } from './CountStats'
import { ImageContainer } from './imageContainer'

export const Market = () => {
  return (
    <section id="marketplace" className="w-full h-full flex flex-col gap-10">
      <CountStats />
      <div className="flex flex-col text-center">
        <Subheading>
          Sua fazenda no ápice tecnológico.
        </Subheading>
        <Heading>
          Seu caminho para comercialização em <Heading className="text-verdeClaro">compliance</Heading> com as demandas <Heading className="text-verdeClaro">ESG</Heading>
        </Heading>
      </div>
      <div className="relative flex items-center justify-center w-full">
        <ImageContainer />
      </div>
      <Link
        to="/marketplace"
        className="sm:hidden flex w-full items-center justify-center gap-2"
      >
        <Text className={'text-center text-white'}>Marketplace</Text>
        <ArrowRight size={24} className="text-verdeClaro" weight="bold" />
      </Link>
    </section>
  )
}
