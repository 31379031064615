import register from '../assets/registerandvali.png'
import logo from '../assets/logo.png'
import collection from '../assets/collection.png'
import nftProvenance from '../assets/nftprovenance.png'
import specMarket from '../assets/specmarket.png'
import club from '../assets/club.png'
import metaverse from '../assets/metaverse.png'
import { Heading } from '../../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../../Components/Screen/Text/Body'

export const EcosystemDesktop = () => {
  return (
    <section
      className="flex flex-col items-center w-full font-extralight h-[640px] relative"
      id="ecosystem"
    >
      <Heading className="text-laranjaClaro top-[14%] absolute">
        Farmie <Heading>Ecossistema</Heading>
      </Heading>

      <div className="absolute top-[52%] items-center justify-center flex w-full">
        <img src={logo} alt="logo" className=" w-40" />
      </div>

      <div className="absolute top-1/4 items-center justify-center flex  flex-col w-full z-20">
        <div
          className="tooltip flex items-center justify-center flex-col"
          data-tip="Para você registrar sua fazenda para sempre, com prova de origem e transparência."
        >
          <Text>Certificação e Registro</Text>
          <img src={register} alt="icon register" className=" w-28 h-28" />
        </div>
      </div>

      <div className="absolute top-[44%] left-0 items-center justify-center flex w-full gap-60">
        <div
          className="items-center justify-center flex tooltip "
          data-tip="Em breve..."
        >
          <Text>Metaverso</Text>
          <img src={metaverse} alt="icon metaverse" className=" w-28 h-28" />
        </div>
        <div
          className="items-center justify-center flex tooltip"
          data-tip="Organize coleções físicas e digitais, dinâmicas para atender às vidas cotidianas de colecionadores e instituições."
        >
          <img src={collection} alt="icon collection" className=" w-28 h-28" />
          <Text>
            Gerencie sua <br /> coleção
          </Text>
        </div>
      </div>
      <div className="absolute top-[65%] right-4 items-center justify-center flex w-full gap-60">
        <div
          className="items-center justify-center flex tooltip"
          data-tip="Com histórico e documentos que provam autenticidade. Para sempre."
        >
          <Text>
            NFT com prova <br />
            de origem
          </Text>
          <img
            src={nftProvenance}
            alt="icon nft provenance"
            className=" w-28 h-28"
          />
        </div>

        <div
          className="items-center justify-center flex tooltip"
          data-tip="Onde o mundo da fazenda interage e colabora para o desenvolvimento da cultura."
        >
          <img src={club} alt="icon club" className=" w-28 h-28" />
          <Text>Farmie Clube</Text>
        </div>
      </div>

      <div className="absolute top-[78%] items-center justify-center flex flex-col w-full ">
        <div
          className="tooltip flex items-center justify-center flex-col"
          data-tip="Servir com a exclusividade necessária. Ao mesmo tempo, permitimos conexões com parceiros (API)."
        >
          <img src={specMarket} alt="icon market" className="w-28 h-28" />
          <Text>Marketplace especializado</Text>
        </div>
      </div>
    </section>
  )
}
