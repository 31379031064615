import './assets/page.css'
import { sendEmailVerification } from 'firebase/auth'
import { ref, update } from 'firebase/database'
import { Link } from 'react-router-dom'
import { ModalSend } from './ModalSend'
import { ModalSkip } from './ModalSkip'
import SimpleFooter from '../../../Components/SimpleFooter/Index'
import { useEffect, useState } from 'react'
import {
  getDownloadURL,
  uploadBytesResumable,
  ref as refStorage,
} from 'firebase/storage'
import { auth, db, storage } from '../../../firebase'
import { NotLogged } from '../../Error/NotLogged'
import { ArrowLeft, Spinner } from '@phosphor-icons/react'
import { Heading } from '../../../Components/Screen/Text/Heading'
import { Subtext, Text } from '../../../Components/Screen/Text/Body'
import { Button, ButtonOutline } from '../../../Components/Screen/Button/button'
import { Inputs } from './Inputs'
import { CircleLight } from '../../../Components/Screen/circleLight'

export default function RegisterDocumentsPage() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [imageFront, setImageFront] = useState(null)
  const [imageBack, setImageBack] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showModalSkip, setShowModalSkip] = useState(false)
  const [showModalSend, setShowModalSend] = useState(false)
  const [err, setErr] = useState(false)
  const [errSizeBack, setErrSizeBack] = useState(false)
  const [errSizeFront, setErrSizeFront] = useState(false)
  const userId = auth?.currentUser?.uid
  async function writeUserData() {
    const metadataBack = {
      contentType: 'image/jpeg',
    }
    let downloadURLBack
    const imageBackipfs = await new Promise((resolve, reject) => {
      const storageRef = refStorage(
        storage,
        'images/' + auth?.currentUser?.uid + '/documents/backImage',
      )
      const uploadTask = uploadBytesResumable(
        storageRef,
        imageFront,
        metadataBack,
      )

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.info('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.info('Upload is paused')
              break
            case 'running':
              console.info('Upload is running')
              break
            default:
              break
          }
        },
        (error) => {
          setErr(error.message)
          reject(error)
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            downloadURLBack = url
            resolve(downloadURLBack)
          })
        },
      )
    })
    const metadata = {
      contentType: 'image/jpeg',
    }
    let downloadURLFront
    const imageFrontipfs = await new Promise((resolve, reject) => {
      const storageRef = refStorage(
        storage,
        'images/' + auth?.currentUser?.uid + '/documents/frontImage',
      )
      const uploadTask = uploadBytesResumable(storageRef, imageFront, metadata)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.info('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.info('Upload is paused')
              break
            case 'running':
              console.info('Upload is running')
              break
            default:
              break
          }
        },
        (error) => {
          setErr(error.message)
          reject(error)
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            downloadURLFront = url
            resolve(downloadURLFront)
          })
        },
      )
    })
    if (imageBackipfs && imageFrontipfs) {
      update(ref(db, 'users/' + userId), {
        DocumentImageBack: downloadURLBack,
        DocumentImageFront: downloadURLFront,
      }).then(
        () => {
          ''
        },
        (error) => {
          console.error(error)
        },
      )
    }
  }

  useEffect(() => {
    if (userId && !localStorage.getItem('user')) {
      localStorage.setItem('user', JSON.stringify(auth))
    }
    if (userId) {
      setIsLoaded(true)
    }
  }, [userId])

  async function handleSubmit(e) {
    setLoading(true)
    e.preventDefault()
    await writeUserData()
    await emailVerify()
    setLoading(false)
    window.scrollTo(0, 0)
    handleOpenModal()
  }

  function handleOpenModal() {
    setShowModalSend(!showModalSend)
  }

  function handleCloseSkip() {
    window.scrollTo(0, 0)
    setShowModalSkip(!showModalSkip)
  }

  async function handleSkipStep() {
    handleCloseSkip()
  }

  async function emailVerify() {
    sendEmailVerification(auth?.currentUser)
  }

  const props = {
    imageFront,
    setImageFront,
    errSizeFront,
    setErrSizeFront,
    imageBack,
    setImageBack,
    errSizeBack,
    setErrSizeBack,
  }

  if (!isLoaded) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-80px-104px)]">
        <div className="flex flex-row items-center justify-center gap-2">
          <Spinner
            size={40}
            weight="bold"
            className="animate-spin text-verdeClaro"
          />
          <Text>Carregando...</Text>
        </div>
      </div>
    )
  }

  return (
    <>
      {userId ? (
        <section className="flex flex-col items-center  bg-preto min-h-[calc(100vh-80px)]  lg:px-20 relative pt-2">
          <CircleLight />
          <CircleLight position={'bottom-0 left-0'} />
          <div className="w-full px-5">
            <Heading className="flex flex-row items-center gap-3 text-verdeClaro">
              <Link to={-1}>
                <ArrowLeft size={32} weight="bold" />
              </Link>
              Registrar documentos
            </Heading>
            <div className="flex flex-col gap-2 py-4">
              <Subtext>
                Para registrar fazendas, criar e negociar NFTs, é necessário
                enviar uma cópia dos seus documentos (passaporte ou carteira de
                identidade nacional). Exigência de conhecimento e verificação de
                identidade (KYC).
              </Subtext>
              <Subtext>
                Nós preservamos a sua identidade acima de tudo, nunca
                revelaremos o seu nome e identidade para ninguém.
              </Subtext>
              <Subtext>
                Os seus dados serão armazenados de forma segura.
              </Subtext>
            </div>
          </div>
          {showModalSkip && (
            <div className="flex justify-center items-center z-20 ml-7 ">
              <ModalSkip handleCloseSkip={handleCloseSkip} />
            </div>
          )}
          {showModalSend && (
            <div className="flex justify-center items-center z-20 ml-7">
              <ModalSend handleOpenModal={handleOpenModal} />
            </div>
          )}

          <Inputs {...props} />

          {err && (
            <Subtext className="text-rose-500">
              Algo deu errado, tente novamente
            </Subtext>
          )}
          <div className="p-5 w-full md:w-1/2 text-center items-center flex flex-col justify-center lg:flex-row gap-2">
            <Button
              onClick={handleSubmit}
              className="w-full"
              disabled={!imageFront || !imageBack || loading}
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>

            <ButtonOutline onClick={handleSkipStep} className="w-full">
              Pular etapa
            </ButtonOutline>
          </div>
          <SimpleFooter />
        </section>
      ) : (
        <NotLogged />
      )}
    </>
  )
}
