import React from 'react'
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  maxWidth: '600px',
  height: '500px',
  borderRadius: "12px",
};

function FarmMap({ nftId }) {
  let center = { lat: -13.37424, lng: -57.852017 }

  let zoom = 13


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCWKI_QgQfZEcirIAT-rq3drF_GWFWMUf4"
  })
  const coordinates = [
    { lat: -13.367546, lng: -57.879794 },
    { lat: -13.354592, lng: -57.827220 },
    { lat: -13.388617, lng: -57.816893 },
    { lat: -13.397939, lng: -57.869158 },
    { lat: -13.398949, lng: -57.874547 },
    { lat: -13.395042, lng: -57.876097 },
    { lat: -13.395176, lng: -57.877107 },
    { lat: -13.393492, lng: -57.877916 },
    { lat: -13.391673, lng: -57.877444 },
    { lat: -13.390528, lng: -57.879263 },
    { lat: -13.388372, lng: -57.880072 },
    { lat: -13.387316, lng: -57.879515 },
    { lat: -13.386481, lng: -57.881298 },
    { lat: -13.384844, lng: -57.881741 },
    { lat: -13.382676, lng: -57.884528 },
    { lat: -13.380995, lng: -57.883157 },
    { lat: -13.379933, lng: -57.882758 },
    { lat: -13.378473, lng: -57.883157 },
    { lat: -13.376615, lng: -57.882935 },
    { lat: -13.375288, lng: -57.882272 },
    { lat: -13.373253, lng: -57.880900 },
    { lat: -13.372059, lng: -57.880900 },
    { lat: -13.369537, lng: -57.880989 },
    { lat: -13.369139, lng: -57.880325 },
    { lat: -13.368387, lng: -57.880591 },
    { lat: -13.367546, lng: -57.879794 }
  ];
  const coordinates2 = [
    { lat: -13.365979, lng: -57.872602 },
    { lat: -13.383672, lng: -57.872330 },
    { lat: -13.397895, lng: -57.868417 },
    { lat: -13.398949, lng: -57.874547 },
    { lat: -13.395042, lng: -57.876097 },
    { lat: -13.395176, lng: -57.877107 },
    { lat: -13.393492, lng: -57.877916 },
    { lat: -13.391673, lng: -57.877444 },
    { lat: -13.390528, lng: -57.879263 },
    { lat: -13.388372, lng: -57.880072 },
    { lat: -13.387316, lng: -57.879515 },
    { lat: -13.386481, lng: -57.881298 },
    { lat: -13.384844, lng: -57.881741 },
    { lat: -13.382676, lng: -57.884528 },
    { lat: -13.380995, lng: -57.883157 },
    { lat: -13.379933, lng: -57.882758 },
    { lat: -13.378473, lng: -57.883157 },
    { lat: -13.376615, lng: -57.882935 },
    { lat: -13.375288, lng: -57.882272 },
    { lat: -13.373253, lng: -57.880900 },
    { lat: -13.372059, lng: -57.880900 },
    { lat: -13.369537, lng: -57.880989 },
    { lat: -13.369139, lng: -57.880325 },
    { lat: -13.368387, lng: -57.880591 },
    { lat: -13.365979, lng: -57.872602 }
  ];
  
  const coordExcedent = [
    { lat: -13.394558, lng: -57.869246 },
    { lat: -13.389636, lng: -57.870573 },
    { lat: -13.389492, lng: -57.868377 },
    { lat: -13.389974, lng: -57.867461 },
    { lat: -13.389950, lng: -57.866495 },
    { lat: -13.390867, lng: -57.865506 },
    { lat: -13.392290, lng: -57.865072 },
    { lat: -13.393014, lng: -57.865458 },
    { lat: -13.394558, lng: -57.869246 }
  ];


  let paths = []
  let options = {}

  paths.push(coordinates);
  options = {
    strokeColor: "red",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#07ffd2",
    fillOpacity: 0,
  }

  const options2 = {
    strokeColor: "#ff14c8",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#ff14c8",
    fillOpacity: 0.35,
  }

  const options3 = {
    strokeColor: "#e4ff4a",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#e4ff4a",
    fillOpacity: 0.35,
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={{
        mapTypeId: "satellite",
      }}
    > 
      <Polygon paths={paths} options={options} />
      <Polygon paths={coordinates2} options={options2} />
      <Polygon paths={coordExcedent} options={options3} />
    </GoogleMap>
  ) : <></>
}

export default React.memo(FarmMap)