import { useNavigate } from 'react-router-dom'

export const Logo = ({ onClick, className, size, width, height }) => {
  const navigate = useNavigate()
  const goHome = () => {
    navigate('/')
  }

  return (
    <img
      width={width || size || '72'}
      height={height || size || '22'}
      className={className || 'hover:cursor-pointer self-center'}
      onClick={onClick || goHome}
      alt="Logo"
      src={require('./logo.png')}
    />
  )
}
