import { X } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { ButtonOutline } from '../../../Components/Screen/Button/button'
import { Text } from '../../../Components/Screen/Text/Body'
import { Heading } from '../../../Components/Screen/Text/Heading'

export const ModalSend = () => {
  const navigate = useNavigate()
  const handleCloseSend = () => {
    navigate('/')
  }

  return (
    <div
      className="modal modal-open backdrop-blur-md w-full h-full z-30"
      id="modal-confirm"
    >
      <div className="modal-box flex flex-col items-center relative bg-preto border border-gray-500 py-10">
        <X
          size={32}
          className="text-laranja absolute right-3 top-2 hover:cursor-pointer"
          onClick={handleCloseSend}
        />

        <Heading className="text-laranja">Confirme o seu email</Heading>
        <Text className="py-4 text-gray-50 text-center">
          O seu registro está quase pronto. <br />
          Último passo é confirmar o email que você receberá. <br />
          Confirme e seja bem-vindo(a) a Farmie.
        </Text>
        <ButtonOutline onClick={handleCloseSend} className="w-52 mt-4">
          Confirmar
        </ButtonOutline>
      </div>
    </div>
  )
}
