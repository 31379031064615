import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'

export const RelatedArts = (props) => {
  const { mQuery, arts, art } = props
  const relatedArts = arts
    ?.filter((a) => a?.userId === art?.userId)
    .filter((a) => a?.withoutqr !== art?.withoutqr)
  const navigate = useNavigate()
  const handleInfos = (art) => {
    navigate(`/farms/${art?.title}`, {
      state: { art, arts },
    })
  }

  return relatedArts?.length > 0 ? (
    <section id="daily" className="w-full py-20 text-left lg:px-20">
      <Heading className={"px-4"}>Outras fazendas do proprietário</Heading>
      <Swiper
        slidesPerView={mQuery ? 5 : 1.25}
        spaceBetween={20}
        className="mySwiper carousel"
      >
        {relatedArts?.map((art, index) => (
          <SwiperSlide
            key={index}
            className="carousel-item cursor-pointer pt-3"
            onClick={() => {
              handleInfos(art)
            }}
          >
            <img
              src={art?.withoutqr}
              alt="art"
              className="object-cover w-full h-64 rounded"
              loading="eager"
            />

            <div className="flex justify-between h-16 w-full p-2 absolute bottom-0 bg-cinzaescuro bg-opacity-70 backdrop-blur items-center">
              <span className="text-xl text-branco">{art?.name}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  ) : (
    <section
      id="daily"
      className="w-full py-20 text-left lg:px-20 flex flex-col gap-4"
    >
      <Heading>Outras fazendas do proprietário</Heading>
      <Text className={'text-info'}>Não foram encontradas fazendas.</Text>
    </section>
  )
}
