import { ref, update } from 'firebase/database'
import { auth, db } from '../../firebase'

// generate a function that will hide a farm from the database
export default async function hideFarm(hidden, art, item, setHide) {
  const uid = auth?.currentUser?.uid
  const key = Object.keys(art)
  const array = Object.values(art)
  const index = array.findIndex((art) => art?.frontqr === item?.frontqr)
  try {
    update(ref(db, `users/${uid}/art/${key[index]}`), {
      hide: hidden,
    })
    setHide(hidden)
  } catch (error) {
    console.error(error)
  }
}
