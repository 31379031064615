import { createUserWithEmailAndPassword } from 'firebase/auth'
import { ref, set } from 'firebase/database'
import { useState } from 'react'
import { auth, db } from '../../firebase'

async function saveUserData({ email, ...props }) {
  const {
    user,
    firstName,
    lastName,
    phone,
    cnpj,
    zipCode,
    address,
    state,
    city,
    country,
    wallet,
    key,
  } = props

  set(ref(db, 'users/' + user?.user?.uid), {
    firstName,
    lastName,
    phone,
    cnpj,
    zipCode,
    address,
    state,
    email,
    city,
    country,
    wallet,
    key,
  })
}

export function useRegister() {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  async function register({ email, password, ...props }) {
    setLoading(true)
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password)
      await saveUserData({ ...props, email, user })
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, register }
}
