import { Subtext } from '../Text/Body'

export const Button = ({ children, className, onClick, as, ...rest }) => {
  const Component = as || 'button'
  return (
    <Component
      className={`bg-verdeClaro rounded-2xl px-4 py-2 hover:bg-verdeClaro/80 transition shadow-md shadow-black/50  ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-black font-medium'}>{children}</Subtext>
    </Component>
  )
}

export const ButtonOutline = ({
  children,
  className,
  onClick,
  as,
  ...rest
}) => {
  const Component = as || 'button'
  return (
    <Component
      className={`bg-transparent border-verdeClaro border-2 rounded-2xl px-4 py-2 hover:bg-verdeClaro/70 transition shadow-md shadow-black/50 ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-white font-medium'}>{children}</Subtext>
    </Component>
  )
}
