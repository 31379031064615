import { createContext, useEffect, useState, useContext } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import useMediaQuery from '../hooks/useMediaQuery'
import { auth, db } from '../firebase'
import { child, get, ref } from 'firebase/database'

export const GlobalContext = createContext({})

export const GlobalContextProvider = ({ children }) => {
  const userId = auth?.currentUser?.uid
  const [userData, setUserData] = useState({})
  const [mediaQuery, setMediaQuery] = useState('')
  const [currentUser, setCurrentUser] = useState({})
  const mediaQueryMobile = useMediaQuery('(max-width: 768px)')
  const mediaQueryTablet = useMediaQuery(
    '(min-width: 768px) and (max-width: 1280px)',
  )
  const mediaQueryDesktop = useMediaQuery('(min-width: 1280px)')

  async function updateUser() {
    try {
      const dbRef = ref(db)
      const snapshot = await get(child(dbRef, `/users/${userId}`))
      if (snapshot?.exists()) {
        const user = snapshot.val()
        setUserData(user)
      } else {
        throw Error('No data available')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function getUserData() {
      try {
        const dbRef = ref(db)
        const snapshot = await get(child(dbRef, `/users/${userId}`))
        if (snapshot?.exists()) {
          const user = snapshot.val()
          setUserData(user)
        } else {
          throw Error('No data available')
        }
      } catch (error) {
        console.log(error)
      }
    }
    userId && getUserData()
  }, [userId])

  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
    return () => {
      unSub()
    }
  }, [])

  useEffect(() => {
    if (mediaQueryMobile) {
      setMediaQuery('mobile')
    } else if (mediaQueryTablet) {
      setMediaQuery('tablet')
    } else if (mediaQueryDesktop) {
      setMediaQuery('desktop')
    }

  }, [mediaQueryMobile, mediaQueryTablet, mediaQueryDesktop])

  return (
    <GlobalContext.Provider
      value={{
        mediaQuery,
        currentUser,
        setCurrentUser,
        userData,
        updateUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error(
      'useGlobalContext must be used within a GlobalContextProvider',
    )
  }
  return context
}
