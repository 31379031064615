import { ArrowRight } from '@phosphor-icons/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Heading, Subheading } from '../../../Components/Screen/Text/Heading'

export const FarmValidator = () => {
  return (
    <section className="flex flex-col py-20 px-6 md:px-0 items-center text-branco w-full select-none">
      <Heading>
        Você gostaria de ser um{' '}
        <Heading className="text-verdeClaro">validador de fazenda?</Heading>
      </Heading>
      <Link to="/register">
        <Subheading className="flex flex-row items-center justify-start gap-4">
          <Subheading className="hover:text-verdeClaro">
            Tudo tem um começo
          </Subheading>
          <button className="flex flex-row items-center font-light text-verdeClaro">
            <ArrowRight size={24} />
          </button>
        </Subheading>
      </Link>
    </section>
  )
}
