import { onValue, ref, update } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase'
import { CheckCircle } from '@phosphor-icons/react'

function Coordenadas() {
  const [coordenadas, setCoordenadas] = useState([])
  const [logged, setLogged] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [farmName, setFarmName] = useState('')
  const [uid, setUid] = useState('')

  function handleLogin() {
    if (username === 'thomas' && password === 'adminthomas@ç') {
      setLogged(true)
    }
  }

  const converterDMSparaDecimal = (dms) => {
    const graus = dms?.split('°')[0]
    const minutos = dms?.split('°')[1]?.split("'")[0]
    const segundos = dms?.split("'")[1]?.split('"')[0]
    const direcao = dms?.split('"')[1]

    let decimal =
      parseFloat(graus) + parseFloat(minutos) / 60 + parseFloat(segundos) / 3600

    if (direcao === 'S' || direcao === 'O') {
      decimal = -decimal
    }

    return decimal
  }

  const extrairCoordenadas = (texto) => {
    const coordenadas = []
    const linhas = texto.split('\n')
    const dados = linhas.slice(13, linhas.length)

    dados.forEach((linha) => {
      const colunas = linha.split(/\s+/)
      const codigo = colunas[0]
      const longitude = converterDMSparaDecimal(colunas[1])
      const latitude = converterDMSparaDecimal(colunas[2])

      coordenadas.push({
        codigo,
        longitude,
        latitude,
      })
    })

    return coordenadas
  }

  const handleLeituraArquivos = (event) => {
    const arquivos = event.target.files
    const coordenadasGeral = []

    for (let i = 0; i < arquivos.length; i++) {
      const reader = new FileReader()

      reader.onload = (e) => {
        const texto = e.target.result
        const coordenadasConvertidas = extrairCoordenadas(texto)
        // if latitude and longitude are not NaN, then add to filteredObj
        const filteredObj = coordenadasConvertidas.filter(
          (coordenada) =>
            !isNaN(coordenada.latitude) && !isNaN(coordenada.longitude),
        )

        coordenadasGeral.push(...filteredObj)

        if (i === arquivos.length - 1) {
          // last file, update coordinates state
          setCoordenadas(coordenadasGeral)
        }
      }

      reader.readAsText(arquivos[i])
    }
  }

  useEffect(() => {
    if (logged) {
      // get all users uid
      const usersRef = ref(db, 'users')
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val()
        const usersUid = Object.keys(data)
        // get all users email
        const usersEmail = usersUid.map((uid) => data[uid])
        // check if email is in usersEmail
        const userUid = usersEmail.findIndex((user) => user.email === email)
        const uidFound = usersUid[userUid]
        console.log({ data, usersUid, usersEmail, uidFound })
        setUid(uidFound)
      })
    }
  }, [email, logged])

  async function handleSave() {
    await update(ref(db, 'users/' + uid + '/coords/' + farmName), {
      ...coordenadas,
    })

    setFarmName('')
    setEmail('')
    setCoordenadas([])
  }

  if (!logged) {
    return (
      <div className="flex flex-col gap-3 mx-auto max-w-2xl py-10">
        <h1>Faça login</h1>
        <input
          type="text"
          placeholder="username"
          className="input input-primary"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          className="input input-primary"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin} className="btn btn-primary mx-auto">
          Login
        </button>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3 mx-auto max-w-2xl py-10">
      <p>Email do usuário a ser salvo dados</p>
      <div className="flex items-center">
        <input
          type="email"
          className="input input-primary w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {uid ? <CheckCircle className="text-2xl text-verdeClaro ml-5" /> : null}
      </div>
      <p>Nome da fazenda</p>
      <input
        type="text"
        className="input input-primary"
        value={farmName}
        onChange={(e) => setFarmName(e.target.value)}
      />
      <button
        onClick={handleSave}
        disabled={!uid || !farmName || !coordenadas.length}
        className="btn btn-primary mx-auto"
      >
        Salvar
      </button>
      <p>Selecione os arquivos</p>
      <input
        type="file"
        accept=".txt"
        className="input"
        multiple
        onChange={handleLeituraArquivos}
      />

      <div className="border rounded-xl p-4">
        <div className="flex border-b">
          <p className="flex-1">Código</p>
          <p className="flex-1">Longitude</p>
          <p className="flex-1">Latitude</p>
        </div>
        {coordenadas.slice(0, 5).map((coordenada, index) => (
          <div key={index} className="flex items-center gap-3">
            <p className="flex-1"> {coordenada.codigo}</p>
            <p className="flex-1"> {coordenada.longitude}</p>
            <p className="flex-1"> {coordenada.latitude}</p>
          </div>
        ))}
      </div>
      {coordenadas.length > 5
        ? `mais ${coordenadas.length - 5} coordenadas...`
        : ``}
      <button
        className="btn btn-info mx-auto"
        onClick={() => {
          setCoordenadas([])
        }}
      >
        Limpar
      </button>
    </div>
  )
}

export default Coordenadas
