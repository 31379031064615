import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep2 } from '../assets/SvgStep2'

export const Step2 = ({ mediaQuery }) => {
  return (
    <article className="flex flex-col gap-4 my-10">
      <div className="lg:flex lg:justify-between lg:gap-24 lg:items-center">
        {mediaQuery && (
          <img
            src="https://images.unsplash.com/photo-1564417947365-8dbc9d0e718e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt="art"
            className="w-1/3 h-[20rem] rounded-lg"
            loading="eager"
          />
        )}
        <div className="lg:flex lg:flex-col gap-3 ">
          <Text className="flex flex-row items-center gap-4">
            <SvgStep2 />
            <Text className="flex flex-col text-white">
              Etapa 2 <Heading className="text-azul">Registro</Heading>
            </Text>
          </Text>
          <ul className="px-6 font-medium list-disc lg:text-lg">
            <li>
              <Text>Registramos as evidencias da sua produção como evidencia comprovatoria</Text>
            </li>
            <li>
              <Text>Processamos a originação de produtos de maneira confiável, aumentando a confiança para o consumidor e outros intervinientes</Text>
            </li>
            <li>
              <Text>Aumentamos a eficiência operacional e mudamos a forma de fazer negócios com tecnologia de ponta</Text>
            </li>
            <li>
              <Text>Apresentamos estudos e relatórios por especialistas na fazenda e no proprietário</Text>
            </li>

          </ul>
        </div>
      </div>
    </article>
  )
}
