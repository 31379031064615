import { useEffect, useState } from 'react'
import { sendPasswordResetEmail as sendPasswordResetEmailFirebase } from 'firebase/auth'
import { auth } from '../../firebase'

function usePasswordReset() {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  async function sendPasswordResetEmail(email) {
    if (!email) {
      setError({ message: 'O campo de e-mail não pode estar vazio' })
      return
    }

    setLoading(true)
    try {
      await sendPasswordResetEmailFirebase(auth, email)
      setSuccess(true)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError(null)
        setSuccess(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [error, success])

  return { error, loading, success, sendPasswordResetEmail }
}

export default usePasswordReset
