export function validateSize(input, setError, setImage) {
  const maxAllowedSize = 5 // 5mb
  const fileSize = input.size / 1024 / 1024 // in MiB
  if (fileSize > maxAllowedSize) {
    setError('File too big! have to be less than 5mb')
  } else {
    setImage(input)
    setError(null)
  }
}
