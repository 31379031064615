import { MagnifyingGlass } from '@phosphor-icons/react'

export const SearchInput = (props) => {
  const { search, setSearch } = props

  return (
    <section className="w-full py-10 px-4 md:px-2 lg:px-0">
      <div className="rounded-3xl relative border border-cinzaclaro md:w-96">
        <input
          type="text"
          id="searchBar"
          placeholder="Dono, Nome da fazenda, CRA, PRA..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          className="w-[90%] p-3 text-white bg-transparent rounded-3xl focus-within:outline-none focus-within:border-0"
        />
        <label htmlFor="searchBar" className="cursor-pointer hover:text-bege">
          <MagnifyingGlass
            size={32}
            className="absolute right-3 top-0 bottom-0 m-auto bg-transparent p-1"
          />
        </label>
      </div>
    </section>
  )
}
