export const SvgStep3 = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dddii_1383_9826)">
        <circle
          cx="23.7075"
          cy="24.0523"
          r="17.105"
          fill="url(#paint0_linear_1383_9826)"
        />
      </g>
      <path
        d="M22.4849 19.0641C20.4047 19.0641 18.7184 20.7504 18.7184 22.8306C18.7184 24.9107 20.4047 26.5971 22.4849 26.5971C24.565 26.5971 26.2514 24.9107 26.2514 22.8306C26.2514 20.7504 24.565 19.0641 22.4849 19.0641ZM17.4629 22.8306C17.4629 20.057 19.7113 17.8086 22.4849 17.8086C25.2584 17.8086 27.5068 20.057 27.5068 22.8306C27.5068 23.9911 27.1132 25.0597 26.4521 25.9101L29.834 29.2919C30.0791 29.5371 30.0791 29.9345 29.834 30.1797C29.5888 30.4248 29.1914 30.4248 28.9462 30.1797L25.5644 26.7978C24.714 27.4589 23.6454 27.8525 22.4849 27.8525C19.7113 27.8525 17.4629 25.6041 17.4629 22.8306ZM22.4849 20.9473C22.8316 20.9473 23.1126 21.2284 23.1126 21.5751V22.2028H23.7404C24.0871 22.2028 24.3681 22.4839 24.3681 22.8306C24.3681 23.1773 24.0871 23.4583 23.7404 23.4583H23.1126V24.0861C23.1126 24.4328 22.8316 24.7138 22.4849 24.7138C22.1382 24.7138 21.8571 24.4328 21.8571 24.0861V23.4583H21.2294C20.8827 23.4583 20.6016 23.1773 20.6016 22.8306C20.6016 22.4839 20.8827 22.2028 21.2294 22.2028H21.8571V21.5751C21.8571 21.2284 22.1382 20.9473 22.4849 20.9473Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_dddii_1383_9826"
          x="0.00427055"
          y="0.348997"
          width="48.5795"
          height="48.5805"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.19942" dy="2.19942" />
          <feGaussianBlur stdDeviation="2.78594" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1383_9826"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.19942" dy="-2.19942" />
          <feGaussianBlur stdDeviation="2.19942" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1383_9826"
            result="effect2_dropShadow_1383_9826"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2.19942" dy="2.19942" />
          <feGaussianBlur stdDeviation="2.19942" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1383_9826"
            result="effect3_dropShadow_1383_9826"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1383_9826"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.146628" dy="-0.146628" />
          <feGaussianBlur stdDeviation="0.146628" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect4_innerShadow_1383_9826"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.146628" dy="0.146628" />
          <feGaussianBlur stdDeviation="0.146628" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_innerShadow_1383_9826"
            result="effect5_innerShadow_1383_9826"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1383_9826"
          x1="6.60254"
          y1="6.94727"
          x2="40.8125"
          y2="41.1573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F1F1F" />
          <stop offset="1" stopColor="#282828" />
        </linearGradient>
      </defs>
    </svg>
  )
}
