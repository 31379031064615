import React from 'react'
import { Button } from '../../../Components/Screen/Button/button'
import { Text } from '../../../Components/Screen/Text/Body'

export const SendButton = (props) => {
  const { loading, error, walletLoading } = props
  return (
    <div className="text-center w-full relative lg:pt-8">
      {error && (
        <Text className="text-red-500 text-center w-full absolute -top-6">
          {error}
        </Text>
      )}
      <Button
        className="w-[40%]"
        type="submit"
        disabled={loading || walletLoading}
      >
        {loading || walletLoading ? 'Registrando...' : 'Registrar'}
      </Button>
    </div>
  )
}
