import { X } from '@phosphor-icons/react'
import { useRef } from 'react'
import { Logo } from '../../../../assets/logo'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import useOnClickOutside from '../../../../utils/clickOutside'

export function ModalAI({ show, setShow }) {
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setShow(false)
  })

  return (
    <>
      <div
        className="flex flex-col items-center bg-preto p-4 rounded-md border-cinzaescuro border min-h-[50vh] relative"
        ref={ref}
      >
        <span
          className="text-laranja absolute right-4 top-4 cursor-pointer"
          onClick={() => setShow(!show)}
        >
          <X size={32} />
        </span>
        <div className="flex justify-center mb-5">
          <Logo
            width="120"
            height="120"
            onClick={() => {
              ''
            }}
          />
        </div>
        <Subheading className="mb-4 text-laranja">
          Inteligência Artificial
        </Subheading>
        <div className="flex flex-col items-center justify-evenly w-full lg:text-xl">
          <Text>Solicitado, mas ainda sem documentos</Text>
        </div>
      </div>
    </>
  )
}
