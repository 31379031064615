import axios from 'axios'

export const createWallet = async () => {
  try {
    const wallet = await axios.post(`${process.env.REACT_APP_contract_api}/createWallet`)
    return wallet?.data
  } catch (error) {
    console.error(error)
  }
}
