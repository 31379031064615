export const Heading = ({ children, className, as, ...rest }) => {
  const Component = as || 'span'
  return (
    <Component
      className={`text-xl sm:text-4xl font-semibold  ${
        className || 'text-gray-50'
      }`}
      {...rest}
    >
      {children}
    </Component>
  )
}

export const Subheading = ({ children, className, as, ...rest }) => {
  const Component = as || 'span'
  return (
    <Component
      className={`text-lg sm:text-2xl font-medium  ${
        className || 'text-gray-50'
      }`}
      {...rest}
    >
      {children}
    </Component>
  )
}
