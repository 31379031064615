import { Subtext, Text } from '../../../Components/Screen/Text/Body'
import { Heading } from '../../../Components/Screen/Text/Heading'

export const Header = () => {
  return (
    <section className="flex flex-col gap-5 pt-6">
      <Heading>
        <Heading className="text-verdeClaro">Prova de origem </Heading>
        <Heading className="text-cinza">na Blockchain </Heading>A solução para
        validar e registrar fazendas para sempre.
      </Heading>
      <Text>
        Um dos maiores impactos dos NFTs no mundo da arte é no registro e
        autenticação de fazendas físicas, pois permite a construção de um
        sistema descentralizado, seguro e perpetuo para substituir antigos
        padrões centralizados. É isso que a
        <Text className="text-verdeClaro"> Farmie</Text> oferece, juntamente com
        um incrível marketplace.
      </Text>
      <Subtext className="text-cinza">
        Todas as etapas são verificadas e validadas
        <Subtext className="font-medium text-bege">
          {' '}
          pela nossa comunidade
        </Subtext>
        .
      </Subtext>
    </section>
  )
}
