import { useEffect, useState } from 'react'
import Footer from '../../../Components/Footer/Index'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { RelatedArts } from './components/RelatedArts'
import { Documents } from './components/Documents'
import { ArtInfos } from './components/ArtInfos'
import { ToastContact } from './components/ToastContact'
import { auth } from '../../../firebase'
import { ToastEmail } from './components/ToastEmail'
import { Modals } from './components/Modals'
import { StatsProfile } from './components/Stats'
import { CircleLight } from '../../../Components/Screen/circleLight'
import { useGlobalContext } from '../../../Context/GlobalContext'

export default function FarmsViewPage() {
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [showAIModal, setShowAIModal] = useState(false)
  const [isToastOpen, setIsToastOpen] = useState(false)
  const mQuery = useMediaQuery('(min-width: 768px)')
  const { userData: user } = useGlobalContext()
  let isEmailSended = false

  const history = useLocation()
  const art = history?.state?.art
  const arts = history?.state?.arts

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [art])

  async function sendEmail() {
    const usuario = auth?.currentUser
    if (isEmailSended) return
    const email = ''
    axios
      .post('https://strdsasipe-payment-createpi.herokuapp.com/sendEmailArt', {
        email,
        art,
        usuario,
      })

      .then(() => {
        ''
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        isEmailSended = true
      })
  }

  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex justify-between flex-col relative">
      <CircleLight position={' top-0 left-0'} />
      <CircleLight position={' top-0 right-0'} />
      <CircleLight position={' bottom-[30%] left-0'} />
      <CircleLight position={' bottom-[30%] right-0'} />

      <div className="relative flex-grow items-center flex flex-col z-20">
        <Modals
          {...{
            art,
            setShowAIModal,
            setShowDocumentsModal,
            showAIModal,
            showDocumentsModal,
            user,
          }}
        />
        <ArtInfos {...{ art }} />

        <Documents
          {...{
            art,
            setShowAIModal,
            showDocumentsModal,
            setShowDocumentsModal,
            showAIModal,
          }}
        />
        <div className="divider w-[80%] self-center" />
        <ToastContact {...{ sendEmail, setIsToastOpen }} />

        <ToastEmail {...{ isEmailSended, isToastOpen }} />
        <div className="w-[90%] mt-5 flex flex-col justify-center items-center border border-cinzaescuro  rounded">
          <StatsProfile art={art} />
        </div>

        <RelatedArts {...{ art, mQuery, arts }} />
      </div>
      <Footer />
    </div>
  )
}
