import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { Text } from '../../../../Components/Screen/Text/Body'
import { ModalAI } from '../modals/AI'
import { ModalDocuments } from '../modals/Documents'

export const Modals = ({
  art,
  user,
  showDocumentsModal,
  setShowDocumentsModal,
  showAIModal,
  setShowAIModal,
}) => {
  let ai = []
  let certificates = []
  let exhibitions = []
  let erroPopup = false

  if (!user?.DocumentImageBack || !user?.DocumentImageFront) {
    erroPopup = true
  } else {
    erroPopup = false
  }

  const ModalError = () => {
    return (
      <div className=" modal modal-open">
        <div className="flex flex-col items-center justify-around p-4 border rounded-lg min-h-[20rem] bg-preto border-cinza ">
          <div />
          <Text>
            Para acessar esta seção, você deve ser um usuário registrado. Por
            favor, faça login ou registre seus documentos.
          </Text>
          <ButtonOutline
            onClick={() => {
              erroPopup = false
              setShowAIModal(false)
              setShowDocumentsModal(false)
            }}
          >
            Ok
          </ButtonOutline>
        </div>
      </div>
    )
  }

  if (art) {
    if (art?.cropDetails) {
      art?.cropDetails?.file?.map((item) => {
        return (exhibitions = [...exhibitions, item])
      })
    }
    if (art?.ai) {
      ai = [...ai, art?.ai]
    }
    if (art?.deflorestation) {
      art?.deflorestation?.file?.map((item) => {
        return (certificates = [...certificates, item])
      })
    }
  }

  return (
    <>
      {showDocumentsModal &&
        (erroPopup ? (
          <ModalError />
        ) : (
          <div className="absolute z-20 backdrop-blur-lg w-full h-full justify-center top-0 pt-44  px-10">
            <ModalDocuments
              show={showDocumentsModal}
              setShow={setShowDocumentsModal}
              exhibitions={exhibitions}
              certificates={certificates}
            />
          </div>
        ))}

      {showAIModal &&
        (erroPopup ? (
          <ModalError />
        ) : (
          <div className="absolute z-20 backdrop-blur-lg w-full h-full justify-center top-0 pt-44  px-10">
            <ModalAI show={showAIModal} setShow={setShowAIModal} ai={ai} />
          </div>
        ))}
    </>
  )
}
