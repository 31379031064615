import { Button } from '../../../../Components/Screen/Button/button'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'

export const ModalNFT = ({ open, setOpen, setStep, mintTickets }) => {
  return (
    <>
      {open && (
        <div className="modal modal-open backdrop-blur">
          <div className="relative modal-box bg-preto border border-gray-500">
            <Heading as="h2" className="mb-4 text-center text-verdeClaro">
              Criar um NFT
            </Heading>

            <form>
              <div className="flex flex-col gap-3">
                <Text as="p">
                  Agora você poderá completar o último passo na certificação da
                  sua fazenda!
                </Text>

                <Text as="p">
                  Carregue uma referência geoespacial de baixa resolução da sua
                  fazenda para sua certificação NFT.
                </Text>

                <Text as="p">
                  Você pode guardar isso para você e negociar toneladas
                  certificadas em nosso mercado de contratos.
                </Text>
              </div>
              <div className="flex flex-row items-center justify-center px-6 mt-10">
                {mintTickets ? (
                  <Button
                    type="button"
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    Sim, Vamos lá!
                  </Button>
                ) : (
                  <Button
                    type="button"
                    onClick={() => {
                      setStep(1)
                      setOpen(false)
                    }}
                  >
                    Você não tem Tickets para criar um NFT
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}
