import axios from 'axios'
import { X } from '@phosphor-icons/react'
import {
  Button,
  ButtonOutline,
} from '../../../../Components/Screen/Button/button'
import { Subtext } from '../../../../Components/Screen/Text/Body'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import { auth } from '../../../../firebase'

export function ModalStep5({
  setStep,
  setIsModalOpen,
  setStep5,
  // step5,
  step2,
  handleSubmit,
  isEmailSended,
  setIsEmailSended,
  // analisys,
}) {
  const user = auth?.currentUser
  const sendEmail = (e) => {
    e.preventDefault()
    if (isEmailSended) return
    const email = 'farmie@contact.com'
    axios
      .post('https://stridsadaspe-payment-createpi.herokuapp.com/sendEmailAI', {
        email,
        step2,
        user,
      })

      .then(() => {
        ''
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setIsEmailSended(true)
      })
  }

  return (
    <div className="modal modal-open backdrop-blur">
      <div className="relative modal-box bg-preto border border-gray-500">
        <label
          htmlFor="modal-ai"
          className="absolute text-laranja right-2 top-2 hover:cursor-pointer"
          onClick={() => setIsModalOpen(false)}
        >
          <X size={32} />
        </label>

        <Subheading
          as="h1"
          className="mb-4 text-2xl font-bold text-center text-laranja"
        >
          Inteligência Artificial
        </Subheading>

        <form>
          <div className="flex flex-col gap-3">
            <Subtext as="p" className=" font-light text-gray-50">
              Você escolheu realizar uma análise de inteligência artificial.
            </Subtext>

            <Subtext as="p" className=" font-light text-gray-50">
              Realizada por um algoritmo, ela tem um custo extra de
              aproximadamente U$750.
            </Subtext>

            <Subtext as="p" className=" font-light">
              Se concordar, clique em SIM e entraremos em contato com você
            </Subtext>
          </div>

          <div className="flex items-center justify-center mt-4 gap-3">
            <ButtonOutline
              className={'border-error hover:bg-error'}
              onClick={() => {
                setIsModalOpen(false)

                setStep5((prevState) => ({
                  ...prevState,
                  analisys: 'Committee',
                }))
              }}
            >
              Agora não
            </ButtonOutline>
            <Button
              type="button"
              onClick={async (e) => {
                await handleSubmit(e)

                sendEmail(e)

                setStep(6)
              }}
            >
              Sim, quero!
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
