import { EnvelopeSimple, Eye, EyeSlash, Lock } from '@phosphor-icons/react'
import { Logo } from '../../../assets/logo'
import { Input, InputDiv } from '../../../Components/Screen/Input/Text'

export const InputForm = (props) => {
  const {
    showPassword,
    handleClickShowPassword,
    mQuery,
    setEmail,
    email,
    setPassword,
    password,
  } = props

  return (
    <>
      <div className="flex justify-center w-full items-center">
        <Logo
          size={mQuery ? '400' : '220'}
          className="relative"
          onClick={() => {
            ''
          }}
        />
      </div>
      <div className="flex flex-col gap-8">
        <InputDiv>
          <EnvelopeSimple size={24} />

          <Input
            type="email"
            id="email"
            placeholder="Login"
            autoComplete="new-email"
            required
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
        </InputDiv>
        <InputDiv>
          <Lock size={24} />
          {!showPassword ? (
            <Input
              required
              type="text"
              placeholder="Password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
          ) : (
            <Input
              required
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
          )}
          <div className="cursor-pointer" onClick={handleClickShowPassword}>
            {showPassword ? <EyeSlash size={24} /> : <Eye size={24} />}
          </div>
        </InputDiv>
      </div>
    </>
  )
}
