import { ref, update } from 'firebase/database'
import { useEffect, useState } from 'react'
import { signOut } from 'firebase/auth'
import useMediaQuery from '../../hooks/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../Context/GlobalContext'
import { auth, db } from '../../firebase'
import { DesktopPage } from './components/Desktop/DesktopPage'
import { MobilePage } from './components/Mobile/MobilePage'
import { getUserNfts } from '../../utils/getUserNfts'
import { CircleLight } from '../../Components/Screen/circleLight'

export default function ProfilePage() {
  const navigate = useNavigate()
  const userId = auth?.currentUser?.uid
  const [showType, setShowType] = useState('Card')
  const { userData, updateUser } = useGlobalContext()
  const [options, setOptions] = useState('CollectionManagement')
  const [nftsCreated, setNftsCreated] = useState([])
  const [allNfts, setAllNfts] = useState([])
  const [isGearOpen, setIsGearOpen] = useState(false)
  const [isGearBackgroundOpen, setIsGearBackgroundOpen] = useState(false)
  const mediaQuery = useMediaQuery('(min-width: 1024px)')
  const maxMediaQuery = useMediaQuery('(max-width: 1280px)')

  let collection = []
  const { art } = userData || []
  if (art) {
    const array = Object.values(art)
    collection = array
  } else {
    collection = []
  }

  async function logoutUser() {
    await signOut(auth)
    localStorage.removeItem('user')
    navigate('/')
  }

  async function saveNfts() {
    try {
      if (!userData?.wallet) return
      const nfts = await getUserNfts(userData?.wallet)
      setAllNfts(nfts)
      // const walletId = userData?.wallet;
      // const nftCreated = nfts?.filter((nft) => nft?.owner_of === walletId);
      setNftsCreated(nfts)
      await update(ref(db, 'users/' + userId), {
        nfts,
      })
      await updateUser()
    } catch {
      console.error('error')
    }
  }

  function handleGear() {
    setIsGearOpen(!isGearOpen)
  }

  function handleGearBackground() {
    setIsGearBackgroundOpen(!isGearBackgroundOpen)
  }

  const props = {
    isGearOpen,
    handleGear,
    handleGearBackground,
    setIsGearBackgroundOpen,
    isGearBackgroundOpen,
    allNfts,
    nftsCreated,
    auth,
    logoutUser,
    showType,
    setShowType,
    userData,
    options,
    setOptions,
    setNftsCreated,
    setAllNfts,
    setIsGearOpen,
    mediaQuery,
    maxMediaQuery,
    navigate,
    collection,
  }

  useEffect(() => {
    userData?.wallet && saveNfts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.wallet])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="flex flex-col bg-preto min-h-[calc(100vh_-_80px)] justify-center lg:relative">
      {mediaQuery ? (
        <>
          <CircleLight position={'top-0'} left />
          <CircleLight position={'bottom-0'} />
          <CircleLight position={'bottom-0'} left />
          <CircleLight position={'top-0'} />

          <DesktopPage {...props} />
        </>
      ) : (
        <MobilePage {...props} />
      )}
    </div>
  )
}
