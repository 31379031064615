import React from 'react'

export const SvgStep4 = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dddii_1383_9833)">
        <circle
          cx="23.605"
          cy="23.3355"
          r="16.605"
          fill="url(#paint0_linear_1383_9833)"
        />
      </g>
      <path
        d="M21.8077 23.0608C22.1443 23.0608 22.4171 22.7879 22.4171 22.4514C22.4171 22.1148 22.1443 21.842 21.8077 21.842C21.4712 21.842 21.1983 22.1148 21.1983 22.4514C21.1983 22.7879 21.4712 23.0608 21.8077 23.0608Z"
        fill="white"
      />
      <path
        d="M23.0265 22.4514C23.0265 22.1148 23.2994 21.842 23.6359 21.842H25.4641C25.8007 21.842 26.0735 22.1148 26.0735 22.4514C26.0735 22.7879 25.8007 23.0608 25.4641 23.0608H23.6359C23.2994 23.0608 23.0265 22.7879 23.0265 22.4514Z"
        fill="white"
      />
      <path
        d="M23.6359 23.6702C23.2994 23.6702 23.0265 23.943 23.0265 24.2796C23.0265 24.6161 23.2994 24.889 23.6359 24.889H25.4641C25.8007 24.889 26.0735 24.6161 26.0735 24.2796C26.0735 23.943 25.8007 23.6702 25.4641 23.6702H23.6359Z"
        fill="white"
      />
      <path
        d="M23.6359 25.4984C23.2994 25.4984 23.0265 25.7712 23.0265 26.1077C23.0265 26.4443 23.2994 26.7171 23.6359 26.7171H25.4641C25.8007 26.7171 26.0735 26.4443 26.0735 26.1077C26.0735 25.7712 25.8007 25.4984 25.4641 25.4984H23.6359Z"
        fill="white"
      />
      <path
        d="M22.4171 24.2796C22.4171 24.6161 22.1443 24.889 21.8077 24.889C21.4712 24.889 21.1983 24.6161 21.1983 24.2796C21.1983 23.943 21.4712 23.6702 21.8077 23.6702C22.1443 23.6702 22.4171 23.943 22.4171 24.2796Z"
        fill="white"
      />
      <path
        d="M21.8077 26.7171C22.1443 26.7171 22.4171 26.4443 22.4171 26.1077C22.4171 25.7712 22.1443 25.4984 21.8077 25.4984C21.4712 25.4984 21.1983 25.7712 21.1983 26.1077C21.1983 26.4443 21.4712 26.7171 21.8077 26.7171Z"
        fill="white"
      />
      <path
        d="M21.8077 17.2715C21.4712 17.2715 21.1983 17.5443 21.1983 17.8809H19.9795C19.3064 17.8809 18.7607 18.4266 18.7607 19.0997V28.2406C18.7607 28.9138 19.3064 29.4594 19.9795 29.4594H27.2923C27.9654 29.4594 28.5111 28.9138 28.5111 28.2406V19.0997C28.5111 18.4266 27.9654 17.8809 27.2923 17.8809H26.0735C26.0735 17.5443 25.8007 17.2715 25.4641 17.2715H21.8077ZM26.0735 19.0997H27.2923V28.2406H19.9795V19.0997H21.1983V19.7091C21.1983 20.0456 21.4712 20.3185 21.8077 20.3185H25.4641C25.8007 20.3185 26.0735 20.0456 26.0735 19.7091V19.0997ZM22.4171 19.0997V18.4903H24.8547V19.0997H22.4171Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_dddii_1383_9833"
          x="0.533916"
          y="0.264385"
          width="47.2917"
          height="47.2926"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.15536" dy="2.15536" />
          <feGaussianBlur stdDeviation="2.73012" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1383_9833"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.15536" dy="-2.15536" />
          <feGaussianBlur stdDeviation="2.15536" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1383_9833"
            result="effect2_dropShadow_1383_9833"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2.15536" dy="2.15536" />
          <feGaussianBlur stdDeviation="2.15536" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1383_9833"
            result="effect3_dropShadow_1383_9833"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1383_9833"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.143691" dy="-0.143691" />
          <feGaussianBlur stdDeviation="0.143691" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0 0.305882 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect4_innerShadow_1383_9833"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.143691" dy="0.143691" />
          <feGaussianBlur stdDeviation="0.143691" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_innerShadow_1383_9833"
            result="effect5_innerShadow_1383_9833"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1383_9833"
          x1="7"
          y1="6.73047"
          x2="40.21"
          y2="39.9405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F1F1F" />
          <stop offset="1" stopColor="#282828" />
        </linearGradient>
      </defs>
    </svg>
  )
}
