import 'react-toastify/dist/ReactToastify.css'
import 'swiper/css/bundle'
import './firebase'
import './index.css'
import { Elements } from '@stripe/react-stripe-js'
import { GlobalContextProvider } from './Context/GlobalContext'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { loadStripe } from '@stripe/stripe-js'
import { ToastContainer } from 'react-toastify'
import RoutesApp from './Routes'

const stripePromise = loadStripe(process.env.REACT_APP_SPK)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Elements stripe={stripePromise}>
        <ToastContainer />
        <RoutesApp />
      </Elements>
    </GlobalContextProvider>
  </React.StrictMode>,
)
