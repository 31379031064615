import { ref, update } from 'firebase/database'
import {
  getDownloadURL,
  ref as refStorage,
  uploadBytesResumable,
} from 'firebase/storage'
import { File, FilePlus, X } from '@phosphor-icons/react'
import { useState } from 'react'
import { db, storage } from '../../../firebase'
import { validateSize } from '../../../utils/validateSizeFile'
import { useGlobalContext } from '../../../Context/GlobalContext'

export const ChangeProfilePic = (props) => {
  const { mediaQuery, auth, setIsGearOpen } = props
  const [pictureFile, setPictureFile] = useState(null)
  const [loadingPicture, setLoadingPicture] = useState(false)
  const [pictureChangeIsDone, setPictureChangeIsDone] = useState(false)
  const [pictureError, setPictureError] = useState(null)
  const { updateUser } = useGlobalContext()

  async function changeProfilePicture(e) {
    e.preventDefault()
    setLoadingPicture(true)
    try {
      const file = pictureFile
      const userId = auth?.currentUser?.uid
      const metadata = {
        contentType: 'image/jpeg',
      }
      let downloadURL
      const profilePicture = await new Promise((resolve, reject) => {
        const storageRef = refStorage(
          storage,
          'images/' + auth?.currentUser?.uid + '/profile/' + file?.name,
        )
        const uploadTask = uploadBytesResumable(storageRef, file, metadata)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.info('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.info('Upload is paused')
                break
              case 'running':
                console.info('Upload is running')
                break
              default:
                break
            }
          },
          (error) => {
            reject(error)
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              downloadURL = url
              resolve(downloadURL)
            })
          },
        )
      })
      if (profilePicture) {
        await update(ref(db, 'users/' + userId), {
          profilePicture,
        })
        await updateUser()
        setPictureChangeIsDone(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingPicture(false)
    }
  }

  if (pictureChangeIsDone) {
    setTimeout(() => {
      setPictureChangeIsDone(false)
      setPictureFile(null)
      setIsGearOpen(false)
    }, 2000)
    clearTimeout()
  }

  if (pictureError) {
    setTimeout(() => {
      setPictureError(null)
    }, 3000)
    clearTimeout()
  }

  return (
    <div
      className={` absolute top-0  ${
        mediaQuery ? 'left-5' : 'right-12'
      } bg-preto  bg-opacity-90 backdrop-blur-sm  flex flex-col gap-4 w-[58%] lg:w-full  rounded-lg font-light text-white p-4`}
    >
      <div className="relative flex flex-col gap-7 pt-2">
        Mudar foto de perfil
        <X
          size={24}
          className="absolute top-2 right-2 cursor-pointer hover:text-laranja"
          onClick={() => setIsGearOpen(false)}
        />
        <label
          htmlFor="fileFront"
          className="cursor-pointer flex items-center justify-center hover:text-laranja"
        >
          {pictureFile ? <File size={32} /> : <FilePlus size={32} />}
        </label>
        <input
          type="file"
          id="fileFront"
          accept="image/*"
          onChange={(e) =>
            validateSize(e.target.files[0], setPictureError, setPictureFile)
          }
          className="hidden"
          disabled={loadingPicture}
        />
        {pictureFile && (
          <div className="flex justify-center">
            <img
              src={URL.createObjectURL(pictureFile)}
              alt="preview"
              className="object-cover w-24 h-24 rounded-full"
              loading="eager"
            />
          </div>
        )}
        {pictureError && (
          <p className="text-red-500 text-center font-semibold">
            {pictureError}
          </p>
        )}
        <button
          disabled={loadingPicture || !pictureFile}
          onClick={changeProfilePicture}
          className="p-2 ml-2 select-none bg-laranja text-preto font-medium rounded-2xl hover:bg-laranja/80"
        >
          {loadingPicture
            ? ' Carregando...'
            : pictureChangeIsDone
            ? 'Done!'
            : 'Upload'}
        </button>
      </div>
    </div>
  )
}
