import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonOutline } from '../../Components/Screen/Button/button'
import { Heading, Subheading } from '../../Components/Screen/Text/Heading'

export default function ErrorPage() {
  const navigate = useNavigate()

  return (
    <div
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1578328819058-b69f3a3b0f6b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 'calc(100vh - 80px)',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="bg-gray-900 flex flex-col p-[5%] rounded-xl">
        <Heading className={'text-error mb-10'}>404!</Heading>
        <Subheading className={'mb-10 text-gray-50'}>
          Oops! A página que você está procurando não existe.
        </Subheading>
        <ButtonOutline type="button" onClick={() => navigate('/')}>
          Voltar para a página inicial
        </ButtonOutline>
      </div>
    </div>
  )
}
