import BePart from '../../Components/BePart/BePart'
import Footer from '../../Components/Footer/Index'
import { Market } from './components/marketplace/market'
import { Ecosystem } from './components/Ecosystem/Ecosystem'
import { Header } from './components/Header/Header'
import { BgCircles } from './components/assets/bgCircles'
import { useGlobalContext } from '../../Context/GlobalContext'

export default function App() {
  const { mediaQuery } = useGlobalContext()
  return (
    <div
      className="min-h-[calc(100vh_-_80px)] flex flex-col relative text-gray-50"
      style={{
        background:
          'radial-gradient(90.16% 143.01% at 45.32% 21.04%, rgba(28, 104, 79, 0.8) 0%, rgba(20, 88, 66, 0.543) 57.08%, rgba(70, 144, 213, 0) 100%)',
      }}
    >
      {mediaQuery === 'mobile' ? null : <BgCircles />}
      {/* <BgCircles /> */}
      <div className="z-10 flex flex-col gap-12">
        <Header />
        <Market />
        <Ecosystem />
        <BePart />
        <Footer />
      </div>
    </div>
  )
}
