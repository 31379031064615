import { ClipboardText, Robot } from '@phosphor-icons/react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Subheading } from '../../../../Components/Screen/Text/Heading'

export const Documents = (props) => {
  const {
    art,
    setShowAIModal,
    showDocumentsModal,
    setShowDocumentsModal,
    showAIModal,
  } = props
  return (
    <div className="flex flex-col w-full md:px-20 justify-center items-center py-10">
      <ul className="flex flex-col gap-10 ">
        <li className="flex gap-2">
          <ClipboardText
            size={48}
            className="bg-cinzaescuro text-branco rounded-full p-2"
          />
          <div className="flex flex-col items-start justify-center">
            <Subheading>Avaliação de Cultivo</Subheading>

            <button
              onClick={() => {
                setShowDocumentsModal(!showDocumentsModal)
                window.scrollTo(0, 0)
              }}
            >
              <Text className={'text-verdeClaro'}>Ver documentos</Text>
            </button>
          </div>
        </li>
        <li className="flex gap-2">
          <Robot
            size={48}
            className="bg-cinzaescuro text-branco rounded-full p-2"
          />
          <div className="flex flex-col items-start justify-center">
            <Subheading>Inteligência Artificial</Subheading>
            {art?.analisys === 'AI' ? (
              <button
                onClick={() => {
                  setShowAIModal(!showAIModal)
                  window.scrollTo(0, 0)
                }}
              >
                <Text className={'text-verdeClaro'}>Ver documentos</Text>
              </button>
            ) : (
              <Text className="text-cinza">Não requerida</Text>
            )}
          </div>
        </li>
      </ul>
    </div>
  )
}
