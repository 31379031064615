import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Heading } from '../../Components/Screen/Text/Heading'
import { Subtext } from '../../Components/Screen/Text/Body'

export const TrendingArts = ({ data: arts, handleInfos, loading }) => {
  return (
    <section id="trending" className="text-left w-full">
      <Heading className="pb-5 text-gray-50">
        Fazendas em <Heading className="text-laranja">Alta</Heading>
      </Heading>
      {loading && (
        <div className="flex justify-center items-center h-96">
          <h1>Carregando fazendas...</h1>
        </div>
      )}
      {!loading && (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          className="mySwiper carousel"
          breakpoints={{
            320: {
              slidesPerView: 1.25,
              spaceBetween: 10,
            },
            460: {
              slidesPerView: 2.25,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4.25,
              spaceBetween: 10,
            },
            1920: {
              slidesPerView: 5.25,
              spaceBetween: -180,
            },
            2560: {
              slidesPerView: 6.25,
              spaceBetween: -500,
            },
          }}
        >
          {arts?.length > 0 ? (
            arts?.map((item, index) => (
              <SwiperSlide
                className="carousel-item cursor-pointer select-none mt-3"
                key={index}
                onClick={() => {
                  handleInfos(item)
                }}
              >
                <div className="relative">
                  <img
                    src={item?.withoutqr}
                    alt="farm"
                    className="object-center object-cover w-72 h-72 rounded-lg"
                  />
                  <div className="text-white py-3 absolute bottom-0 left-0 w-full bg-cinzaescuro bg-opacity-60 backdrop-blur">
                    <Subtext className="px-3">{item?.name}</Subtext>
                  </div>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <div className="flex justify-start items-center h-96 w-full">
              <h1>Nenhuma fazenda encontrada</h1>
            </div>
          )}
        </Swiper>
      )}
    </section>
  )
}
