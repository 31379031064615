import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { signOut } from 'firebase/auth'
import { auth } from '../../../firebase'
import { CircleLight } from '../../../Components/Screen/circleLight'
import { useGlobalContext } from '../../../Context/GlobalContext'
import { DesktopPage } from './Desktop/DesktopPage'
import { MobilePage } from './Mobile/MobilePage'
import { getUserNfts } from '../../../utils/getUserNfts'

export default function EditProfilePage() {
  const navigate = useNavigate()
  const { userData } = useGlobalContext()
  const [isGearOpen, setIsGearOpen] = useState(false)
  const [isGearBackgroundOpen, setIsGearBackgroundOpen] = useState(false)
  const mediaQuery = useMediaQuery('(min-width: 1024px)')
  const maxMediaQuery = useMediaQuery('(max-width: 1280px)')
  const [nftsCreated, setNftsCreated] = useState([])
  const [allNfts, setAllNfts] = useState([])

  let collection = []
  const { art } = userData || []
  if (art) {
    const array = Object.values(art)
    collection = array
  } else {
    collection = []
  }
  async function logoutUser() {
    await signOut(auth)
    localStorage.removeItem('user')
    navigate('/')
  }

  function handleGear() {
    setIsGearOpen(!isGearOpen)
  }

  function handleGearBackground() {
    setIsGearBackgroundOpen(!isGearBackgroundOpen)
  }

  async function saveNfts() {
    try {
      if (!userData?.wallet) return
      const nfts = await getUserNfts(userData?.wallet)
      setAllNfts(nfts)

      setNftsCreated(nfts)
    } catch {
      console.error('error')
    }
  }

  const props = {
    isGearOpen,
    handleGear,
    handleGearBackground,
    setIsGearBackgroundOpen,
    isGearBackgroundOpen,
    auth,
    logoutUser,
    userData,
    setIsGearOpen,
    mediaQuery,
    maxMediaQuery,
    navigate,
    collection,
    nftsCreated,
    allNfts,
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    userData?.wallet && saveNfts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.wallet])

  return (
    <div className="flex flex-col bg-preto min-h-[calc(100vh_-_80px)] justify-center lg:relative">
      {mediaQuery ? (
        <>
          <CircleLight position={'top-0'} left />
          <CircleLight position={'bottom-0'} />
          <CircleLight position={'bottom-0'} left />
          <CircleLight position={'top-0'} />

          <DesktopPage {...props} />
        </>
      ) : (
        <MobilePage {...props} />
      )}
    </div>
  )
}
