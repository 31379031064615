import { Spinner } from '@phosphor-icons/react'
import React from 'react'
import { Text } from '../Text/Body'

export const Loading = ({ className }) => {
  return (
    <div
      className={`w-full ${
        className || 'h-[70vh]'
      } bg-preto flex items-center justify-center gap-2 `}
    >
      <Spinner size={40} className="animate-spin text-verdeClaro" />
      <Text>Carregando, por favor aguarde... </Text>
    </div>
  )
}
