import { Logo } from '../../assets/logo'
import useMediaQuery from '../../hooks/useMediaQuery'
import SimpleFooter from '../SimpleFooter/Index'
import { Infos } from './components/Desktop/Infos'
import { InfosMobile } from './components/Mobile/Infos'

export default function Footer() {
  const mediaQuery = useMediaQuery('(min-width: 768px)')

  const Mobile = () => {
    return (
      <>
        <div className="flex flex-col justify-center items-center py-3 gap-3">
          <div className='flex flex-row justify-around w-full'>
            <Logo />
            <InfosMobile />
          </div>
          <SimpleFooter />
        </div>
      </>
    )
  }

  const Desktop = () => {
    return (
      <div className="flex flex-col items-center justify-around pt-3 h-full w-full">
        <div className="flex justify-around w-full">
          <Logo width={200} height={200} />
          <Infos />
        </div>
        <SimpleFooter />
      </div>
    )
  }

  return (
    <div
      style={{
        background:
          'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0.7) 67.08%, rgba(70, 144, 213, 0) 100%)',
      }}
      className="w-full z-20 md:h-48"
    >
      {mediaQuery ? <Desktop /> : <Mobile />}
    </div>
  )
}
