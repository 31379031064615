import { ButtonOutline } from '../Screen/Button/button'
import { Subtext, Text } from '../Screen/Text/Body'

export const NeedDocuments = ({ text }) => {
  return (
    <div className={`toast toast-top z-50 top-20 w-full p-0 m-0`}>
      <div
        className="alert alert-info w-full rounded-none h-[80px]"
        style={{
          background:
            'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(23,30,0, 0.7) 0%, rgba(0,0,0, 0.5447917) 77.08%, rgba(23, 33, 213, 0) 100%)',
          backdropFilter: 'blur(20px)',
        }}
      >
        <div className="w-full justify-around flex">
          <Subtext>{text}</Subtext>
          <a href="/register/documents">
            <ButtonOutline className={'border-red-500 hover:bg-red-500'}>
              <Text className="font-bold">Upload</Text>
            </ButtonOutline>
          </a>
        </div>
      </div>
    </div>
  )
}
