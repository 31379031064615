import Footer from '../../Components/Footer/Index'
import { CircleLight } from '../../Components/Screen/circleLight'
import { Header } from './components/header'
import { Partners } from './components/Partners'
import { Solution } from './components/Solution'

export const MarketplacePage = () => {
  return (
    <div className=" bg-preto min-h-[calc(100vh-80px)] flex flex-col justify-between relative">
      <CircleLight position={'top-[20%]'} left />
      <CircleLight position={'top-[10%]'} />
      <CircleLight position={'top-[60%]'} />
      <CircleLight position={'top-[80%]'} left />
      <div className="flex flex-col flex-grow mb-10 p-3 md:p-10 lg:px-40 gap-10 md:gap-28 z-10">
        <Header />
        <Solution />
        <Partners />
      </div>

      <Footer />
    </div>
  )
}
