import { Subtext, Text } from '../../../../Components/Screen/Text/Body'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import { child, get, ref } from 'firebase/database'
import { db } from '../../../../firebase'
import { useEffect, useState } from 'react'

export const StatsProfile = ({ art }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    async function getUser() {
      const dbRef = ref(db)
      return get(child(dbRef, `/users/${art?.userId}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const users = snapshot.val()
            setData(users)
          } else {
            console.log('No data available')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    getUser()
  }, [art?.userId])

  const collectionSize = Object?.values(data?.art || [])?.length
  const level = Math.floor(collectionSize / 10) + 1
  const progress = collectionSize % 10
  const nextLevel = level + 1
  const progressPercentage = (progress / 10) * 100

  return (
    <div className="flex w-full px-10 md:px-20 justify-between font-light flex-col  sm:flex-row p-4 md:my-10 md:mt-20 gap-4 lg:items-center">
      <div className="flex gap-10 flex-col  sm:flex-row items-center w-full justify-between">
        <img
          src={
            data?.profilePicture ||
            'https://clinicforspecialchildren.org/wp-content/uploads/2016/08/avatar-placeholder.gif'
          }
          alt="profile"
          className="w-32 h-32 rounded-full object-cover object-center"
          loading="eager"
        />
        <div className="flex flex-col  text-cinza">
          <Subheading>
            {data?.firstName} {data?.lastName}
          </Subheading>
          <Subtext>{data?.typeProfile}</Subtext>
          <Text>
            Fazendas: <span>{collectionSize || 0}</span>
          </Text>

          <Text>Nível na Farmie: {level}</Text>
          <Text>
            {progress} / 10 para o nível {nextLevel}
          </Text>
          <div className="w-full h-2 bg-cinzaescuro/50 rounded-full">
            <div
              className="h-full bg-cinzaescuro/100 rounded-full"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
        </div>
        <div className="text-cinza flex flex-col">
          <Text>Coleção de NFTs: {data?.nfts?.length || 0}</Text>
          <div className="text-cinza flex flex-col">
            <Text>NFTs criados: {data?.nfts?.length || 0}</Text>
          </div>
        </div>
        <div className="flex flex-col">
          <Text>Passes</Text>
          <Subtext>{data?.artTicket || '0'} farm pass disponível</Subtext>
          <Subtext>{data?.mintTicket || '0'} mint pass disponível</Subtext>
        </div>
      </div>
    </div>
  )
}
