import * as React from 'react'

export const CowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="svg-icon"
    style={{
      verticalAlign: 'middle',
      fill: 'currentColor',
      overflow: 'hidden',
    }}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M957.33 316.567c-23.75-31.844-72.61-63.955-133.395-71.809 5.589-17.8 9.175-37.445 10.658-58.69 2.851-40.816-2.993-74.39-3.243-75.796a40.68 40.68 0 0 0-32.893-32.939 40.618 40.618 0 0 0-7.172-.64 40.685 40.685 0 0 0-35.08 20.1c-19.029 32.253-51.265 67.782-72.496 75.15-41.605-26.265-93.868-40.438-152.376-41.195a40.1 40.1 0 0 0-2.75-.092c-.356 0-.712.005-1.065.012a52.646 52.646 0 0 0-1.065-.012c-.927 0-1.845.03-2.756.092-58.5.76-110.761 14.93-152.367 41.195-21.236-7.37-53.482-42.913-72.505-75.172-7.402-12.554-20.84-20.046-35.066-20.048-2.392 0-4.805.214-7.218.648-16.754 3.024-29.87 16.133-32.846 32.894-1.764 9.93-11.89 73.822 7.175 134.507-60.67 7.903-109.437 39.95-133.156 71.75-16.75 22.46-22.535 46.385-16.29 67.372 12.31 41.377 61.907 67.081 129.432 67.081 26.872 0 53.381-4.174 77.606-11.95l34.765 258.956c-1.58 4.487-3.85 9.904-6.209 15.53-8.45 20.17-18.97 46.168-18.97 75.74 0 72.983 56.741 124.854 137.985 124.854h182.971c81.243 0 137.985-51.871 137.985-124.853 0-29.573-10.52-55.12-18.97-75.29-2.36-5.626-4.63-11.27-6.209-15.756l34.765-259.07c24.227 7.778 50.735 11.896 77.608 11.896 67.529 0 117.125-25.736 129.432-67.115 6.244-20.981.46-44.897-16.285-67.35zm-22.706 55.723c-7.144 24.018-45.799 38.003-90.44 38.003-37.679 0-79.625-9.967-110.463-32.271l-43.363 322.997c5.274 23.606 25.952 54.47 25.952 88.107 0 53.754-43.56 84.047-97.303 84.047H436.036c-53.742 0-97.303-30.293-97.303-84.047 0-33.637 20.678-64.114 25.952-87.72L321.32 378.215c-30.843 22.307-72.78 32.176-110.462 32.176-44.634 0-83.296-14.036-90.441-38.052-8.449-28.39 53.212-88.713 138.911-88.708 14.6.002 29.912 1.742 45.679 5.7-62.26-53.656-41.258-171.95-41.258-171.95s56.562 96.628 114.842 96.628c1.13 0 2.891-.04 4.023-.114 37.637-27.805 84.06-42.133 145.081-42.493 61.022.36 107.273 14.685 144.906 42.491 1.136.075 2.49.112 3.625.112 58.278 0 115.07-96.63 115.07-96.63s20.995 118.284-41.264 171.941c15.774-3.96 31.072-5.71 45.679-5.71 85.69.002 147.36 60.296 138.913 88.685z" />
    <path d="M669.376 413.207c0-14.808-11.988-26.819-26.82-26.819-14.844 0-26.827 12.023-26.827 26.819 0 14.83 11.983 26.829 26.826 26.829 14.833 0 26.821-12.01 26.821-26.829zM404.962 386.388c-14.843 0-26.826 12.023-26.826 26.819 0 14.83 11.983 26.829 26.826 26.829 14.834 0 26.821-12.01 26.821-26.829.001-14.808-11.987-26.819-26.82-26.819zM400.902 751.926c-14.842 0-26.824 12.023-26.824 26.817 0 14.834 11.982 26.831 26.824 26.831 14.837 0 26.824-12.01 26.824-26.83 0-14.807-11.987-26.818-26.824-26.818zM653.714 751.926c-14.842 0-26.824 12.023-26.824 26.817 0 14.834 11.982 26.831 26.824 26.831 14.835 0 26.824-12.01 26.824-26.83 0-14.807-11.989-26.818-26.824-26.818z" />
  </svg>
)
