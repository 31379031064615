import { Link } from 'react-router-dom'
import { Button } from '../../../../Components/Screen/Button/button'
import { Subheading } from '../../../../Components/Screen/Text/Heading'

export const FarmImage = () => {
  return (
    <>
      <div className="relative">
        <img
          src={require('../assets/imageArt.jpg')}
          alt="Art"
          className="w-full h-full rounded"
        />
        <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-preto rounded-b"></div>
        <div className="absolute z-10 text-left bottom-4 left-4 ">
          <Subheading>Fazenda São João</Subheading>
        </div>
      </div>

      <Link to="/farm/register" className="w-full py-4">
        <Button className="w-full">Registre sua fazenda</Button>
      </Link>
    </>
  )
}
