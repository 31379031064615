import SimpleFooter from '../../../../Components/SimpleFooter/Index'
import { BgImage } from '../../components/Mobile/BgImage'
import { UserInfo } from '../../components/Mobile/UserInfo'
import { FormEditProfileMobile } from './Form'

export const MobilePage = (props) => {
  const { userData } = props

  if (!userData)
    return (
      <div className="bg-preto self-center flex">
        <div>Carregando...</div>
      </div>
    )

  return (
    <>
      <section className="flex flex-col items-center justify-center w-full mb-28">
        <BgImage {...props} />

        <UserInfo {...props} />
      </section>
      <FormEditProfileMobile {...props} />

      <SimpleFooter />
    </>
  )
}
