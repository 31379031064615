import { Heading } from '../../../Components/Screen/Text/Heading'
import { Partner } from './Partner'

export const Partners = () => {
  return (
    <div className="flex flex-col gap-10 bg-black/20 p-10 rounded-2xl ">
      <Heading>
        Nossos <span className="text-verdeClaro"> Parceiros</span>
      </Heading>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 justify-center items-baseline">
        {partnersList?.map((partner) => (
          <Partner key={partner.name} {...partner} />
        ))}
      </div>
    </div>
  )
}

const partnersList = [
  {
    name: 'EcoFy',
    description:
      'EcoFy é líder global em certificação orgânica, com mais de 30 anos de experiência no campo.',
    image: require('../assets/ecoFy.png'),
  },
  {
    name: 'EnergyRails',
    description:
      'EnergyRails é uma empresa brasileira que trabalha com a certificação de produtos e serviços sustentáveis.',

    image: require('../assets/EnergyRails.png'),
  },

  {
    name: 'Terratek',
    description:
      'A Terratek oferece soluções inovadoras para problemas de engenharia complexos. Com uma equipe multidisciplinar, nossos serviços incluem consultoria e projetos, suporte para a implementação de obras e gerenciamento de ativos.',
    image: require('../assets/terraTek.png'),
  },
  {
    name: 'Sova Group',
    description:
      'A Sova Group é uma empresa brasileira que trabalha com a certificação de produtos e serviços sustentáveis.',
    image: require('../assets/SovaGroup.png'),
  },
]
