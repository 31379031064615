import { ArrowRight } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { Button } from '../../../../Components/Screen/Button/button'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'

export default function ArtRegisterSuccessPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="flex flex-col items-center justify-center md:items-start  md:px-20 xl:px-40 h-[70vh]">
      <div className="w-full flex items-center mt-10 px-5">
        <Heading as="h1" className="text-branco">
          Sua solicitação foi enviada com sucesso!
        </Heading>
      </div>

      <Text as="p" className="px-5 text-branco font-light mt-4">
        <span className="text-laranja">Fique atualizado</span> sobre o processo
        no seu perfil.
      </Text>

      <Text as="p" className="px-5 text-branco font-light mt-4">
        A História da Fazenda <span className="text-laranja"> Agradece </span>
        pela sua colaboração!
      </Text>

      <div className="flex flex-col gap-8 md:gap-0 md:flex-row justify-between items-center w-full mt-20 pt-20 px-5">
        <a href="/farm/register">
          <Button type="button">Cadastrar nova fazenda</Button>
        </a>
        <a href="/profile">
          <div className="text-branco font-light text-xs flex flex-col items-center gap-2 hover:text-laranja">
            <div className="text-branco flex items-center gap-2 lg:text-2xl ">
              Voltar para
              <span className="font-bold hover:text-laranja">Farmie</span>
              <i className="text-laranja lg:text-4xl">
                <ArrowRight size={24} />
              </i>
            </div>
          </div>
        </a>
      </div>
    </section>
  )
}
