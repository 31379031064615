import { Text } from '../../../Components/Screen/Text/Body'
import { Heading } from '../../../Components/Screen/Text/Heading'

export const Header = () => {
  return (
    <div className="p-6 text-center flex flex-col gap-3">
      <Heading>
        O Maior Banco de Dados de{' '}
        <Heading className="text-verdeClaro">Prova de Origem</Heading>{' '}
        descentralizado na Blockchain
      </Heading>
      <Text>
        Transparente, apoiado pela ciência e aberto para a comunidade. Pesquise
        e aprenda sobre a história da fazenda.
      </Text>
    </div>
  )
}
