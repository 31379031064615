import { useState } from 'react'

export default function NFTList({ nft }) {
  const [show, setShow] = useState(false)
  const [errorImage, setErrorImage] = useState(false)
  const item = JSON.parse(nft?.metadata)

  const HandleGetType = () =>
    item?.attributes?.map((item) => {
      return (
        <span key={item.trait_type}>
          {item.trait_type} : {item.value}
        </span>
      )
    })

  const Image = () => {
    return (
      <>
        <img
          src={item?.image}
          alt={item?.name}
          className={`w-20 h-20 rounded-full ${
            show ? '' : 'animate-pulse bg-white/40'
          }`}
          onLoad={() => setShow(true)}
          onError={() => {
            setErrorImage(true)
            setShow(true)
          }}
          loading="eager"
        />
        {errorImage && (
          <div className="absolute top-[50%] pl-5  z-40 text-white">
            Falha ao carregar, por favor recarregue a página
          </div>
        )}
        {!show && (
          <div className="absolute z-40 text-white top-[50%] pl-5 ">
            Carregando a partir da Blockchain, por favor aguarde...
          </div>
        )}
      </>
    )
  }

  return (
    <div className="flex flex-row items-center justify-around w-full select-none">
      <Image alt="" />
      <div className="flex flex-col text-xs text-cinza">
        <span className="text-branco font-light text-xl">{item?.name}</span>
        <HandleGetType />
      </div>
    </div>
  )
}
