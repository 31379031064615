import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  // TelegramLogo,
  // XLogo
} from '@phosphor-icons/react'
import { Heading } from '../Screen/Text/Heading'

import { InfoSquare } from './infoSquare'

const size = 48

export default function BePart() {
  return (
    <section className="flex flex-col w-full text-center" id="community">
      <Heading>
        Faça parte da <Heading className="text-azul">Farmie</Heading>
      </Heading>
      <div className="flex flex-col gap-3 w-full items-center">
        <div className="flex flex-row gap-3 mt-10">
          <InfoSquare
            link={'https://www.instagram.com/farmie_oficial/'}
          >
            <InstagramLogo size={size} />
          </InfoSquare>
          {/* <InfoSquare
          // link={'https://twitter.com/'}
          >
            <XLogo size={size} />
          </InfoSquare> */}
        {/* </div> */}
        {/* <div className="flex flex-row gap-3"> */}
          <InfoSquare
          link={'https://www.facebook.com/farmie.io/'}
          >
            <FacebookLogo size={size} />
          </InfoSquare>
          <InfoSquare
          link={'https://www.linkedin.com/company/farmie-agro'}
          >
            <LinkedinLogo size={size} />
          </InfoSquare>
        </div>
      </div>
    </section>
  )
}
