import CheckoutForm from './CheckoutForm'

export default function ButtonCheckout({ props }) {
  const { isBuying } = props
  return isBuying ? (
    <div style={formStyle}>
      <CheckoutForm props={props} />
    </div>
  ) : null
}

const formStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  zIndex: '1',
  backdropFilter: 'blur(8px)',
}
