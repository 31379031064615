
// import { v4 as uuidv4 } from 'uuid'

class StorageClient {
  async storeFiles(file) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await fetch(`${process.env.REACT_APP_apiURL}/uploadIPFS`, {
      method: 'POST',
      body: formData,
    })
    const data = await response.json()
    const cid = data.cid
    const imageURI = `https://ipfs.filebase.io/ipfs/${cid}`
    return imageURI
  }
}

export default StorageClient
