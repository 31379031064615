import { Text } from '../../../../Components/Screen/Text/Body'

export const ListCollection = ({ item, handleInfos }) => {
  return (
    <div className="flex select-none items-center justify-between border-b border-b-cinza p-4">
      <img
        src={item.withoutqr}
        alt="farm"
        className="w-16 h-16 rounded-full object-cover object-center"
      />
      <Text>{item.name}</Text>
      <Text
        className="hover:cursor-pointer hover:text-laranja text-gray-50"
        onClick={() => handleInfos(item)}
      >
        Ver
      </Text>
    </div>
  )
}
